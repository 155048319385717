import { createContext, FC, ReactNode, useMemo, useState } from 'react';

import { importThemeModule } from '../../utils/helpers';

interface DEIThemeContextType {
  getDEITheme: (moduleType: string) => Promise<any>;
}

export const DEIThemeContext = createContext<DEIThemeContextType>({
  getDEITheme: () => Promise.resolve({}),
});

interface TemplateToPath {
  [key: string]: string;
}

interface DEIThemeProviderProps {
  children: ReactNode;
}

export const DEIThemeProvider: FC<DEIThemeProviderProps> = ({ children }) => {
  const [themes, setThemes] = useState<TemplateToPath>({});

  const getModuleFileName = (templateType: string) => {
    switch (templateType) {
      case 'fr-dei':
        return 'fr-dei.js';
      case 'fr-dei-lite':
        return 'fr-dei-lite.js';
      case 'fp-dei':
        return 'fp-dei.js';
      case 'fr-esg':
        return 'fr-esg.js';
      case 'fr-esg-lite':
        return 'fr-esg-lite.js';
      case 'demo-esg':
        return 'demo-esg.js';
      case 'demo-dei':
        return 'demo-dei.js';
      default:
        return 'demo-dei.js';
    }
  };
  async function getDEITheme(templateType: string): Promise<any> {
    if (themes[templateType]) {
      return themes[templateType];
    }
    try {
      const module = await importThemeModule(getModuleFileName(templateType));
      const themeFunc = module.default;
      const themeConstants = themeFunc(templateType);
      setThemes((prev) => ({ ...prev, [templateType]: themeConstants }));
      return themeConstants;
    } catch (e) {
      // TODO: handle error
    }
  }

  const value = useMemo(() => ({ getDEITheme }), [themes]);

  return <DEIThemeContext.Provider value={value}>{children}</DEIThemeContext.Provider>;
};
