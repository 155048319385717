import { post } from '../../../utils/backend';
import { toArray } from '../../../utils/helpers';

function mapListSelectData(list) {
  const questions = [];
  toArray(
    list.forEach((item) => {
      let question = questions.find((q) => q.id === item.questionId);
      if (question) {
        const dimensionValue = question.dimensionValues.find((d) => d.name === item.name);
        if (dimensionValue) {
          dimensionValue.answers.push({
            value: item.value,
            title: item.title,
            valueDim: item.valueName,
            score: item.score,
          });
        } else {
          question.dimensionValues.push({
            name: item.name,
            answers: [
              {
                value: item.value,
                title: item.title,
                valueDim: item.valueName,
                score: item.score,
              },
            ],
          });
        }
      } else {
        question = {
          id: item.questionId,
          categoryName: item.category,
          dimensionValues: [
            {
              name: item.name,
              answers: [
                {
                  value: item.value,
                  title: item.title,
                  valueDim: item.valueName,
                  score: item.score,
                },
              ],
            },
          ],
        };
        questions.push(question);
      }
    }),
  );
  questions.forEach((question) => {
    question.dimensionValues.forEach((item) => {
      item.answers.sort((a, b) => b.score - a.score);
      item.answers = item.answers.slice(0, 5);
    });
  });
  return questions;
}

async function getListSelectsByDimension(
  filters,
  questions,
  settings,
  allFilters,
  dimensionsData,
  dimensionList,
) {
  const promices = [];
  const listSelectsDimensions = [];
  dimensionList
    .filter((dimension) => dimension.visibleAppendix)
    .forEach((dimension) => {
      const dimensionData = dimensionsData.find((d) => d.id === dimension.dimension_code);
      if (dimensionData) {
        const promise = new Promise((resolve) => {
          const dimensionValues = dimensionData.dimensions.map((i) => i.name).slice(0, 50);
          let consolidatedDimensionValueList = {};
          if (dimension.consolidateCols && toArray(dimensionData.bucketDimensionValues).length) {
            consolidatedDimensionValueList = {
              q1: dimensionData.bucketDimensionValues[0],
              q2: dimensionData.bucketDimensionValues[1],
              q3: dimensionData.bucketDimensionValues[2],
              q4: dimensionData.bucketDimensionValues[3],
            };
          }
          const subPromices = [];
          if (dimension.status === 0 && !dimension.topInfluencersHidden) {
            subPromices.push(
              post('/reporting/discussion_materials/list_select_values_by_dimension', {
                ...filters,
                dimensionValues: dimensionValues.slice(0, 4),
                filter: dimension.dimension_code,
              }),
            );
            if (dimension.consolidateCols && toArray(dimensionData.bucketDimensionValues).length) {
              consolidatedDimensionValueList = {
                q1: dimensionData.bucketDimensionValues[0],
                q2: dimensionData.bucketDimensionValues[1],
                q3: dimensionData.bucketDimensionValues[2],
                q4: dimensionData.bucketDimensionValues[3],
              };
              subPromices.push(
                post(
                  '/reporting/discussion_materials/list_select_values_by_consolidated_dimensions',
                  {
                    ...filters,
                    filter: dimension.dimension_code,
                    consolidatedDimensionValueList,
                  },
                ),
              );
            }
          }

          Promise.all(subPromices).then((data) => {
            if (toArray(data[0]?.listSelectValues).length) {
              let consolidatedQuestions = [];
              if (toArray(data[1]?.listSelectValues).length) {
                consolidatedQuestions = mapListSelectData(data[1].listSelectValues, allFilters);
              }
              listSelectsDimensions.push({
                questions: mapListSelectData(data[0].listSelectValues, allFilters),
                dimensionValues,
                id: dimension.dimension_code,
                dimensionId: dimension.id,
                name: dimension.display_name,
                consolidatedQuestions,
              });
            }
            resolve();
          });
        });
        promices.push(promise);
      }
    });
  await Promise.all(promices);
  return { listSelectsDimensions };
}

export default getListSelectsByDimension;
