import { cloneDeep } from '../helpers';

interface WithChildren {
  children?: any[];
  surveyname: string;
}

function filterSurveyByName<T extends WithChildren>(
  searchText: string,
  item: { children?: T[]; surveyname: string },
): boolean {
  if (item.children?.length) {
    item.children = item.children.filter((child) => filterSurveyByName(searchText, child));
    if (!item.children.length) {
      item.children = undefined;
    }
  }
  return !!(item.surveyname.toLowerCase().includes(searchText.toLowerCase()) || item.children);
}

export function filterSurveysByName<T extends WithChildren>(surveys: T[], searchText: string): T[] {
  return (cloneDeep(surveys) as typeof surveys).filter((survey) =>
    filterSurveyByName(searchText, survey),
  );
}
