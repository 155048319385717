import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { chain, Dictionary, minBy, uniqBy } from 'lodash';

import { SlideSettingsType } from '../../constants';
import { getSurveyFilterParams } from '../../hooks/useReportData/helpers/surveyFilterHelpers';
import { getCommon } from '../../hooks/useReportData/useRawReportData';
import { FiltersUI } from '../filters';

import { CommonDataUI, DMRawDataUI, dmSlideFunctions, FetcherArgs } from './fetchers';
import { DataFunctionUI, Fetcher, LoadingStateEnum, SlidesConfigUI } from './shared';

interface DiscussionMaterialsContextType {
  triggerRefetch: () => void;
  refetchToken: number;
  updateDataFunctions: (newSlideConfigs: SlidesConfigUI) => void;
  setFilters: Dispatch<SetStateAction<Partial<FiltersUI>>>;
  setSlideSettings: Dispatch<SetStateAction<any>>;
  loadingPt: number;
  rawData: DMRawDataUI;
  loadingQueueNum: number;
  slideLoadingPts: { [key: string]: number };
}

const DiscussionMaterialsContext = createContext<DiscussionMaterialsContextType | undefined>(
  undefined,
);

interface DiscussionMaterialsProviderProps {
  children: React.ReactNode;
}

export const DiscussionMaterialsProvider: React.FC<DiscussionMaterialsProviderProps> = ({
  children,
}) => {
  const [refetchToken, setRefetchToken] = useState<number>(0);
  const [slideConfigs, setSlideConfigs] = useState<SlidesConfigUI>({});
  const [dataFunctions, setDataFunctions] = useState<DataFunctionUI[]>([]);
  const [filters, setFilters] = useState<Partial<FiltersUI>>({});
  const [slideSettings, setSlideSettings] = useState<SlideSettingsType>();
  const [commonsLoaded, setCommonLoaded] = useState(false);
  const [commonsError, setCommonError] = useState<Error | null>(null);
  const [commonData, setCommonData] = useState<CommonDataUI>();
  const loadingQueueNumRef = useRef(0); // prevents seting data from previous call if user rapidly clicking apply

  const triggerRefetch = () => setRefetchToken((prev) => prev + 1);

  const resetedDataFunctions = () => {
    const newDataFunctions: DataFunctionUI[] = dataFunctions.map((df) => {
      const res: DataFunctionUI = {
        ...df,
        data: null,
        error: null,
        loadingState: LoadingStateEnum.NOT_STARTED,
      };
      return res;
    });
    return newDataFunctions;
  };

  // Load commons
  useEffect(() => {
    setCommonLoaded(false);
    if (!filters.orgId) {
      return;
    }
    const fetchData = async () => {
      try {
        const res = await getCommon(filters);
        setCommonData(Object.assign({}, ...res));
        setCommonLoaded(true);
      } catch (e) {
        setCommonError(e as Error);
      } finally {
        setCommonLoaded(true);
      }
    };

    fetchData();
  }, [filters.orgId, refetchToken]);

  const refetch = async () => {
    if (
      !commonsLoaded ||
      !commonData ||
      !filters.orgId ||
      filters.loading ||
      !filters.hierarchyList?.length ||
      !filters.surveyIdList?.length ||
      commonsError ||
      !Object.keys(slideConfigs).length ||
      !slideSettings
    ) {
      return;
    }

    const inClosurelLoadingQueueN = loadingQueueNumRef.current + 1;
    loadingQueueNumRef.current = inClosurelLoadingQueueN;

    let newDataFunctions = resetedDataFunctions();
    let hasError = false;

    setDataFunctions(newDataFunctions);

    const sortedPriorities = chain(newDataFunctions)
      .filter((x) => x.loadingState === LoadingStateEnum.NOT_STARTED)
      .map((x) => x.priority)
      .uniq()
      .sort()
      .value();

    // not forEach because it creates additional async scope
    for (let i = 0; i < sortedPriorities.length && !hasError; i++) {
      if (loadingQueueNumRef.current !== inClosurelLoadingQueueN) {
        return;
      }
      const priority = sortedPriorities[i];
      // set "loading"
      newDataFunctions = newDataFunctions.map((x) => ({
        ...x,
        loadingState: x.priority === priority ? LoadingStateEnum.LOADING : x.loadingState,
      }));
      setDataFunctions(newDataFunctions);

      // BE works slower if just run all fetchers in paralel
      // Also we want some fetchers with higher oriority to be executed first
      // so we group all fetchers by priority and run groups one by one
      const getchersFroup = newDataFunctions.filter((x) => x.priority === priority);

      // eslint-disable-next-line no-await-in-loop
      await Promise.allSettled(
        // eslint-disable-next-line no-loop-func
        getchersFroup.map(async (df) => {
          try {
            const reqFilters = getSurveyFilterParams(filters as FiltersUI);
            const args: FetcherArgs = {
              allFilters: filters,
              categories: commonData.categories,
              commonData,
              dimensionsList: commonData.dimensionsList,
              filters: reqFilters,
              questions: commonData.questions,
              slideSettings,
              visibleSlides: Object.keys(slideConfigs).filter((key) => slideConfigs[key]?.visible),
            };
            const res = await df.fetcher(args);

            if (loadingQueueNumRef.current !== inClosurelLoadingQueueN) {
              return;
            }

            newDataFunctions = newDataFunctions.map((x) =>
              x.fetcher === df.fetcher
                ? { ...x, data: res, loadingState: LoadingStateEnum.SUCCESS }
                : x,
            );
            setDataFunctions(newDataFunctions);
          } catch (err) {
            if (loadingQueueNumRef.current !== inClosurelLoadingQueueN) {
              return;
            }
            const error = err as Error;
            hasError = true;
            newDataFunctions = newDataFunctions.map((x) =>
              x.fetcher === df.fetcher ? { ...x, error, loadingState: LoadingStateEnum.ERROR } : x,
            );

            setDataFunctions(newDataFunctions);
            console.error('Error fetching data', df, error);
          }
        }),
      );
    }
  };

  useEffect(() => {
    refetch();
  }, [refetchToken, commonsLoaded, filters, slideSettings]);

  const updateDataFunctions = (newSlideConfigs: SlidesConfigUI) => {
    setSlideConfigs({ ...newSlideConfigs });
    const newDataFunctions: DataFunctionUI[] = Object.keys(newSlideConfigs).flatMap((key) => {
      const visible = newSlideConfigs[key].visible;
      if (!visible) return [];

      const priority = newSlideConfigs[key].priority;
      const functions: Fetcher[] =
        (dmSlideFunctions[key as keyof typeof dmSlideFunctions] as Fetcher[]) ?? [];
      return functions.flatMap((fn: Fetcher) => {
        const res: DataFunctionUI = {
          data: null,
          error: null,
          fetcher: fn,
          loadingState: LoadingStateEnum.NOT_STARTED,
          priority,
        };

        return [res];
      });
    });

    const uniqDataFunctions = uniqBy(newDataFunctions, (x) => x.fetcher);
    const uniqDataFunctionsWithHighestPrio = uniqDataFunctions.map((df) => ({
      ...df,
      priority:
        minBy(
          newDataFunctions.filter((x) => x.fetcher === df.fetcher),
          (x) => x.priority,
        )?.priority || df.priority,
    }));

    const newMergedDataFunctions: DataFunctionUI[] = [
      ...dataFunctions.filter(
        (x) => !newDataFunctions.some((newFn) => newFn.fetcher === x.fetcher),
      ),
      ...uniqDataFunctionsWithHighestPrio,
    ];

    setDataFunctions(newMergedDataFunctions);
  };

  const rawData: DMRawDataUI = useMemo(
    () => Object.assign({}, commonData, ...dataFunctions.map((x) => x.data)),
    [commonData, dataFunctions],
  );

  const slideLoadingPts: Dictionary<number> = useMemo(() => {
    return Object.keys(dmSlideFunctions).reduce<Dictionary<number>>((acc, key) => {
      const fns = dmSlideFunctions[key as keyof typeof dmSlideFunctions] as Fetcher[];
      if (filters.loading) return { ...acc, [key]: 0 };
      if (!commonsLoaded) return { ...acc, [key]: 10 };
      const total = fns.length;
      const executed = dataFunctions.filter(
        (df) =>
          fns.includes(df.fetcher) &&
          [LoadingStateEnum.ERROR, LoadingStateEnum.SUCCESS].includes(df.loadingState),
      ).length;
      const pt = Math.min(Math.round((executed / total) * 90 + 10), 100);
      return { ...acc, [key]: pt };
    }, {} as Dictionary<number>);
  }, [dataFunctions]);

  const loadingPt = useMemo(() => {
    if (filters.loading) return 0;
    if (!commonsLoaded) return 10;
    const total = dataFunctions.length;
    if (total === 0) return 100;
    const executed = dataFunctions.filter((df) =>
      [LoadingStateEnum.ERROR, LoadingStateEnum.SUCCESS].includes(df.loadingState),
    ).length;
    return Math.min(Math.round((executed / total) * 90 + 10), 100);
  }, [dataFunctions]);

  const value = useMemo(
    () => ({
      error: commonsError ?? dataFunctions.find((x) => x.error)?.error,
      loadingPt,
      loadingQueueNum: loadingQueueNumRef.current,
      rawData,
      refetchToken,
      setFilters,
      setSlideSettings,
      slideLoadingPts,
      triggerRefetch,
      updateDataFunctions,
    }),
    [
      triggerRefetch,
      refetchToken,
      loadingPt,
      commonsError,
      dataFunctions,
      rawData,
      slideLoadingPts,
      loadingQueueNumRef.current,
    ],
  );

  return (
    <DiscussionMaterialsContext.Provider value={value}>
      {children}
    </DiscussionMaterialsContext.Provider>
  );
};

export const useDiscussionMaterials = (
  slideConfigs: SlidesConfigUI,
  filters: Partial<FiltersUI>,
  slideSettings: SlideSettingsType,
): DiscussionMaterialsContextType => {
  const context = useContext(DiscussionMaterialsContext);

  if (!context) {
    throw new Error('useDiscussionMaterials must be used within a DiscussionMaterialsProvider');
  }

  useEffect(() => {
    context.setFilters(filters);
    context.setSlideSettings(slideSettings);
    context.updateDataFunctions(slideConfigs);
  }, [filters.orgId, (filters as any).reportType, filters.filterIdList, slideSettings]);

  return context;
};
