import { post } from '../../../utils/backend';
import { toArray } from '../../../utils/helpers';

import { cleanStatementDataByDimmension } from './cleanStatementData';
import { getBaseUrl } from '.';

async function getFollowingQuestionsStatementsByDimension(
  filters,
  questions,
  settings,
  allFilters,
  dimensionsData,
  dimensionList,
) {
  const promices = [];
  const baseUrl = getBaseUrl(allFilters);
  const questionsStatementsByDimensions = [];
  dimensionList
    .filter((dimension) => dimension.visible)
    .forEach((dimension) => {
      const dimensionData = dimensionsData.find((d) => d.id === dimension.dimension_code);
      if (dimensionData) {
        const promise = new Promise((resolve) => {
          const dimensionValues = dimensionData.dimensions.map((i) => i.name).slice(0, 50);
          const subPromices = [
            post(`${baseUrl}/statements_for_following_questions_by_dimension`, {
              ...filters,
              dimensionValues,
              filter: dimension.dimension_code,
            }),
          ];
          Promise.all(subPromices).then((data) => {
            if (toArray(data[0]?.categoryQuestions).length) {
              let questionsCleanedConsolidated = [];
              const questionsCleaned = cleanStatementDataByDimmension(
                data[0].categoryQuestions,
                allFilters,
                settings,
                questions,
              );
              if (toArray(data[1]?.categoryQuestions).length) {
                questionsCleanedConsolidated = cleanStatementDataByDimmension(
                  data[1].categoryQuestions,
                  allFilters,
                  settings,
                  questions,
                );
              }
              if (toArray(questionsCleaned).length) {
                questionsStatementsByDimensions.push({
                  categoryQuestions: questionsCleaned,
                  questionsCleanedConsolidated,
                  dimensionValues,
                  id: dimension.dimension_code,
                  dimensionId: dimension.id,
                  name: dimension.display_name,
                });
              }
            }
            resolve();
          });
        });
        promices.push(promise);
      }
    });
  await Promise.all(promices);
  return { questionsStatementsByDimensions };
}

export default getFollowingQuestionsStatementsByDimension;
