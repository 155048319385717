import { SURVEY_TYPE } from '../../../constants';
import { setMaxRequestsProgressPercent } from '../../../utils/backend';
import { cloneDeep, toArray } from '../../../utils/helpers';

import getFixedByDimension from './getFixedByDimension';
import getFixedDistributionByDimension from './getFixedDistributionByDimension';
import getFollowingQuestionsStatementsByDimension from './getFollowingQuestionsStatementsByDimension';
import getListSelectsByDimension from './getListselectsByDimension';
import getNPSByDimension from './getNpsByDimension';
import getStatementsByDimension from './getStatementsByDimension';

async function getResultsByDimension(
  filters,
  questions,
  categories,
  dimensions,
  settings,
  allFilters,
  slides,
  commonData,
) {
  const { dimensionsData, topInfluencersCount, dimensionList, defaultDimensionList } =
    await getFixedByDimension(
      filters,
      questions,
      categories,
      dimensions,
      settings,
      allFilters,
      slides,
      commonData,
    );
  setMaxRequestsProgressPercent(100);
  // the following requests should be executed in parallel
  const [
    statementsByDimensionRes,
    followingQuestionsStatementsByDimensionRes,
    listSelectsByDimensionRes,
    fixedDistributionByDimensionRes,
    NPSByDimensionRes,
  ] = await Promise.all([
    getStatementsByDimension(
      filters,
      questions,
      settings,
      allFilters,
      dimensionsData,
      dimensionList,
    ),

    allFilters.reportType !== 'vcp'
      ? getFollowingQuestionsStatementsByDimension(
          filters,
          questions,
          settings,
          allFilters,
          dimensionsData,
          dimensionList,
        )
      : Promise.resolve(null),

    allFilters.reportType !== 'vcp'
      ? getListSelectsByDimension(
          filters,
          questions,
          settings,
          allFilters,
          dimensionsData,
          dimensionList,
        )
      : Promise.resolve(null),

    allFilters.reportType !== 'vcp' && allFilters.surveyType !== SURVEY_TYPE.CHILD_360
      ? getFixedDistributionByDimension(
          filters,
          questions,
          settings,
          allFilters,
          dimensionsData,
          dimensionList,
        )
      : Promise.resolve(null),

    getNPSByDimension(filters, questions, allFilters, dimensionsData, dimensionList),
  ]);

  const statementsDimensions = statementsByDimensionRes?.statementsDimensions ?? [];
  const questionsStatementsByDimensions =
    followingQuestionsStatementsByDimensionRes?.questionsStatementsByDimensions ?? [];
  const listSelectsDimensions = listSelectsByDimensionRes?.listSelectsDimensions ?? [];
  const fixedDistributionByDimension =
    fixedDistributionByDimensionRes?.fixedDistributionByDimension ?? [];
  const npsBreakdownByDimension = NPSByDimensionRes?.npsBreakdownByDimension ?? [];
  const npsBreakdown = NPSByDimensionRes?.npsBreakdown ?? [];

  dimensionsData.forEach((dim, index) => {
    dim.order = dimensionList.findIndex((i) => i.id === dim.dimensionId);
    if (dim.order === -1) {
      dim.order = index;
    }
    if (dim.order === -1) {
      dim.order = 1000;
    }
  });
  dimensionsData.sort((a, b) => a.order - b.order);
  npsBreakdownByDimension.forEach((dim, index) => {
    dim.order = dimensionList.findIndex((i) => i.id === dim.dimensionId);
    if (dim.order === -1) {
      dim.order = index;
    }
    if (dim.order === -1) {
      dim.order = 1000;
    }
  });
  npsBreakdownByDimension.sort((a, b) => a.order - b.order);
  questionsStatementsByDimensions.forEach((dim) => {
    dim.order = dimensionList.findIndex((i) => i.id === dim.dimensionId);
    if (dim.order === -1) {
      dim.order = dimensionsData.findIndex((i) => i.id === dim.id);
    }
    if (dim.order === -1) {
      dim.order = 1000;
    }
  });
  questionsStatementsByDimensions.sort((a, b) => a.order - b.order);
  statementsDimensions.forEach((dim) => {
    dim.order = dimensionList.findIndex((i) => i.id === dim.dimensionId);
    if (dim.order === -1) {
      dim.order = dimensionsData.findIndex((i) => i.id === dim.id);
    }
    if (dim.order === -1) {
      dim.order = 1000;
    }
  });
  statementsDimensions.sort((a, b) => a.order - b.order);
  listSelectsDimensions.forEach((dim) => {
    dim.order = dimensionList.findIndex((i) => i.id === dim.dimensionId);
    if (dim.order === -1) {
      dim.order = dimensionsData.findIndex((i) => i.id === dim.id);
    }
    if (dim.order === -1) {
      dim.order = 1000;
    }
  });
  listSelectsDimensions.sort((a, b) => a.order - b.order);

  fixedDistributionByDimension.forEach((dim) => {
    dim.order = dimensionList.findIndex((i) => i.id === dim.dimensionId);
    if (dim.order === -1) {
      dim.order = dimensionsData.findIndex((i) => i.id === dim.id);
    }
    if (dim.order === -1) {
      dim.order = 1000;
    }
  });
  fixedDistributionByDimension.sort((a, b) => a.order - b.order);

  let dimensionsForBreakdown = [];
  if (dimensionsData.length) {
    dimensionsForBreakdown = dimensionsData.map((item) => ({
      id: item.id,
      name: item.name,
      dimensions: item.dimensions,
    }));
  } else if (statementsDimensions.length) {
    dimensionsForBreakdown = statementsDimensions.map((item) => ({
      id: item.id,
      name: item.name,
      dimensions: item.dimensionValues.map((i) => ({
        name: i,
      })),
    }));
  }
  const overallDimensionData = cloneDeep(dimensionsData).filter(
    (item) => dimensionList.find((i) => i.dimension_code === item.id)?.visible,
  );
  const appendixDimensionData = dimensionsData
    .filter((item) => toArray(item.questions).length)
    .filter((item) => dimensionList.find((i) => i.dimension_code === item.id)?.visibleAppendix);
  const appendixNpsDimensionData = npsBreakdownByDimension.filter(
    (item) => dimensionList.find((i) => i.dimension_code === item.code)?.visibleAppendix,
  );
  const vcpDimensionData = cloneDeep(dimensionsData).filter(
    (item) => dimensionList.find((i) => i.dimension_code === item.id)?.vcpDimension,
  );
  return {
    fixedByDimension: appendixDimensionData,
    vcpDimensionData,
    dimensionsSettings: dimensionList,
    defaultDimensionSettings: defaultDimensionList,
    overallDataByDimension: overallDimensionData,
    statementsDimensions,
    listSelectsDimensions,
    fixedDistributionByDimension,
    questionsStatementsByDimensions,
    dimensionsForBreakdown,
    topInfluencersCount,
    overallNpsBreakdownByDimension: npsBreakdownByDimension,
    npsBreakdownByDimension: appendixNpsDimensionData,
    npsBreakdown,
  };
}

export default getResultsByDimension;
