import { Spin } from 'antd';
import classNames from 'classnames';

import './styles.scss';

interface LoaderProps {
  passThruPointerEvents?: boolean;
}

const Loader: React.FC<LoaderProps> = (passThruPointerEvents) => (
  <div className={classNames('loader-wrapper', { 'pass-pointer-events': passThruPointerEvents })}>
    <Spin size="large" />
  </div>
);

export default Loader;
