/* eslint-disable func-names */
/* eslint-disable no-underscore-dangle */
export function initHotjar() {
  (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function (...args) {
        (h.hj.q = h.hj.q || []).push(...args);
      };
    h._hjSettings = { hjid: parseInt(process.env.HOTJAR_SITE_ID, 10), hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
}
