import React, { useContext } from 'react';
import { Checkbox, Form, Input, Modal } from 'antd';
import PropTypes from 'prop-types';

import { UserContext } from '../../../store/user';
import { FIELD_IS_REQUIRED } from '../../../utils/validation';

const { TextArea } = Input;

const SendPreview = React.memo(({ visible, onCancel, onSave }) => {
  const [form] = Form.useForm();
  const { user } = useContext(UserContext);

  function onSubmit() {
    form.validateFields().then((values) => {
      const list = values.emails.split(',');
      if (values.sendMe) {
        list.push(user.email);
      }
      onSave(list);
      onCancel();
    });
  }

  return (
    <Modal
      title="Send preview email"
      open={visible}
      onOk={onSubmit}
      onCancel={onCancel}
      okText="Send"
      afterClose={() => form.resetFields()}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="emails"
          label="Enter a comma-separated list of email addresses"
          rules={[{ required: true, message: FIELD_IS_REQUIRED }]}
        >
          <TextArea autoSize />
        </Form.Item>
        <Form.Item name="sendMe" valuePropName="checked">
          <Checkbox>Send myself a copy</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
});

SendPreview.defaultProps = {
  visible: false,
  onSave: PropTypes.func,
  onCancel: () => {},
};

SendPreview.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default SendPreview;
