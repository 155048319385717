import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isNil } from 'lodash';

import { get, post } from '../../utils/backend';
import { UserContext } from '../user';

export interface StandardDimensionRes {
  standardDimensionCode: string;
  standardDimensionDisplayName: string;
  organizationDimensionId: number;
  organizationDimensionCode: string;
  organizationDimensionDisplayName: string;
}

interface UpdateStandardDimensionReq {
  organization_dimension_code: string;
  standard_dimension_code: string;
}

interface StandardDimensionsContextType {
  standardDimensions: StandardDimensionRes[];
  standardDimensionCodeToOrgDimensionId: Record<string, number | string>;
  standardDimensionCodeToOrgDimensionCode: Record<string, number | string>;
  updateStandardDimensions: (newMapping: UpdateStandardDimensionReq[]) => Promise<void>;
  refetchStandardDimensions: () => Promise<void>;
  loading: boolean;
}

const StandardDimensionsContext = createContext<StandardDimensionsContextType | undefined>(
  undefined,
);

export const fetchStandardDimensions = async (): Promise<StandardDimensionRes[]> => {
  const res = await get('/dimension_metadata/standard_dimensions_mappings', { browserCache: true });
  return res.filter((x: StandardDimensionRes) => !isNil(x));
};

export const standardDimensionsToMap = (
  standardDimensions: StandardDimensionRes[],
  field: keyof StandardDimensionRes,
): Record<string, number | string> =>
  (standardDimensions ?? []).reduce(
    (acc, item) => ({
      ...acc,
      [item.standardDimensionCode]: item[field],
    }),
    {},
  );

interface StandardDimensionsProviderProps {
  children: React.ReactNode;
}

export const StandardDimensionsProvider: React.FC<StandardDimensionsProviderProps> = ({
  children,
}) => {
  const [standardDimensions, setStandardDimensions] = useState<StandardDimensionRes[]>([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);

  const refetchStandardDimensions = useCallback(async () => {
    if (!user?.userId) return;
    try {
      setLoading(true);
      const res = await fetchStandardDimensions();
      setStandardDimensions(res);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch dimensions:', error);
    } finally {
      setLoading(false);
    }
  }, [user?.userId]);

  const updateStandardDimensions = useCallback(
    async (newMapping: UpdateStandardDimensionReq[]) => {
      try {
        setLoading(true);
        await post('/dimension_metadata/standard_dimensions_mappings', newMapping);
        await refetchStandardDimensions();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to update dimensions:', error);
      } finally {
        setLoading(false);
      }
    },
    [refetchStandardDimensions],
  );

  useEffect(() => {
    refetchStandardDimensions();
  }, [refetchStandardDimensions, user?.userId]);

  const value = useMemo(
    () => ({
      loading,
      refetchStandardDimensions,
      standardDimensionCodeToOrgDimensionCode: standardDimensionsToMap(
        standardDimensions,
        'organizationDimensionCode',
      ),
      standardDimensionCodeToOrgDimensionId: standardDimensionsToMap(
        standardDimensions,
        'organizationDimensionId',
      ),
      standardDimensions,
      updateStandardDimensions,
    }),
    [standardDimensions, updateStandardDimensions, loading],
  );

  return (
    <StandardDimensionsContext.Provider value={value}>
      {children}
    </StandardDimensionsContext.Provider>
  );
};

export const useStandardDimensions = (): StandardDimensionsContextType => {
  const context = useContext(StandardDimensionsContext);
  if (!context) {
    throw new Error('useStandardDimensions must be used within a StandardDimensionsProvider');
  }
  return context;
};
