import { FiltersUI } from '../../../store/filters';

export interface SurveyFilterParamsUI {
  compareGroupIdList: number[] | undefined;
  compareHierarchyList: string[] | undefined;
  compareSurveyIdList: number[] | undefined;
  groupIdList: number[] | string[] | undefined;
  hierarchyList: string[] | undefined;
  organizationId: number | undefined;
  portfolioId: number | undefined;
  surveyIdList: number[] | undefined;
  surveyType: string | undefined;
}

export function getSurveyFilterParams(filters: FiltersUI): SurveyFilterParamsUI {
  const {
    orgId,
    portfolioId,
    surveyType,
    surveyIdList,
    hierarchyList,
    groupIdList,
    compareSurveyIdList,
    compareHierarchyList,
    compareGroupIdList,
    filterHierarchyList,
    filterGroupIdList,
    compareFilterHierarchyList,
    compareFilterGroupIdList,
  } = filters;
  return {
    compareGroupIdList: compareFilterGroupIdList?.length
      ? compareFilterGroupIdList
      : compareGroupIdList,
    compareHierarchyList: compareFilterHierarchyList?.length
      ? compareFilterHierarchyList
      : compareHierarchyList,

    compareSurveyIdList,

    // TODO: hotfix, requires proper solution and getting rid of hierarchy later
    groupIdList: filterGroupIdList?.length ? filterGroupIdList : groupIdList || hierarchyList,

    hierarchyList: filterHierarchyList?.length ? filterHierarchyList : hierarchyList,

    organizationId: orgId,
    portfolioId,
    surveyIdList,
    surveyType,
  };
}
