import React from 'react';
import { Table } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import { DEFAULT_UI_DATE_FORMAT } from '../../../../constants';
import { capitalize, showTableTotal } from '../../../../utils/helpers';

const History = React.memo(({ items }) => {
  const columns = [
    {
      title: 'Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: '25%',
      sorter: (a, b) => moment(a.updatedAt).valueOf() - moment(b.updatedAt).valueOf(),
      render: (value) => moment(value).format(`${DEFAULT_UI_DATE_FORMAT} hh:mm:ss a`),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '20%',
      render: (value) => capitalize(value).replaceAll('_', ' '),
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      width: '35%',
    },
    {
      title: 'Updated by',
      dataIndex: 'userName',
      key: 'userName',
      width: '20%',
    },
  ];

  return (
    <Table
      className="history-table"
      size="small"
      dataSource={items}
      columns={columns}
      showSorterTooltip={false}
      pagination={{
        showSizeChanger: true,
        showTotal: showTableTotal,
        defaultPageSize: 20,
      }}
    />
  );
});

History.defaultProps = {
  items: [],
};

History.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      updatedAt: PropTypes.number,
      action: PropTypes.string,
      details: PropTypes.string,
      userName: PropTypes.string,
    }),
  ),
};

export default History;
