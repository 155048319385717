import React, { useState } from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { cloneDeep } from '../../utils/helpers';

import './styles.scss';

const { TextArea } = Input;

const Tager = React.memo(({ items, setItems, placeholder, showEmptyTextArea, disabled }) => {
  const [value, setValue] = useState('');

  function onTextAreaChange(event) {
    const newValue = event.target.value;
    const lines = newValue.split(/[\r\n]+/);
    const linesWithText = lines.filter((item) => item.trim().length);
    const linesCount = lines.length;
    const linesWithTextCount = linesWithText.length;
    setValue((prevValue) => {
      if (prevValue === '') {
        setItems([
          ...items,
          ...linesWithText.map((item) => ({
            text: item,
            id: uuidv4(),
            isNew: true,
          })),
        ]);
        return lines[linesCount - 1].trimStart();
      }
      if (newValue === '') {
        setItems(items.slice(0, items.length - 1));
        return newValue;
      }
      const newItems = items.map((item, index) =>
        index === items.length - 1
          ? {
              text: lines[0],
              id: item.id,
              isNew: item.isNew,
            }
          : item,
      );
      if (linesWithTextCount > 1) {
        newItems.push(
          ...linesWithText.slice(1).map((item) => ({
            text: item.trim(),
            id: uuidv4(),
            isNew: true,
          })),
        );
      }
      setItems(newItems);
      return lines[linesCount - 1];
    });
  }

  function onItemChange(newValue, id) {
    const index = items.findIndex((item) => item.id === id);
    const linesWithText = newValue.split(/[\r\n]+/).filter((item) => item.trim().length);
    if (index === -1) {
      return;
    }
    const list = cloneDeep(items);
    if (newValue.length) {
      list.splice(
        index,
        1,
        ...linesWithText.map((item, i) => ({
          id: i === 0 ? id : uuidv4(),
          text: linesWithText.length > 1 ? item.trim() : item,
          isNew: i === 0 ? items[index].isNew : true,
        })),
      );
      setItems(list);
    } else if (items.length < 2 && !showEmptyTextArea) {
      setItems([{ id: 1, text: '', isNew: true }]);
    } else {
      list.splice(index, 1);
      setItems(list);
    }
  }

  function onKeyPress(event, id) {
    if (
      !event.target.value.length ||
      event.keyCode !== 8 ||
      event.target.selectionStart !== 0 ||
      event.target.selectionEnd !== 0
    ) {
      return;
    }
    const index = items.findIndex((item) => item.id === id);
    if (!(index > 0)) {
      return;
    }
    event.preventDefault();
    const list = cloneDeep(items);
    list[index - 1].text = [list[index - 1].text, list[index].text].join(' ');
    list.splice(index, 1);
    setItems(list);
  }

  return (
    <div className="tager">
      {items.map(
        (item, index) =>
          (value === '' || index !== items.length - 1) && (
            <TextArea
              autoSize
              value={item.text}
              key={item.id}
              onChange={(e) => onItemChange(e.target.value, item.id)}
              onKeyDown={(e) => onKeyPress(e, item.id)}
              allowClear
              className="tager__input"
              disabled={disabled}
            />
          ),
      )}
      {showEmptyTextArea && (
        <TextArea
          autoSize={{ minRows: 2 }}
          placeholder={placeholder}
          value={value}
          onChange={onTextAreaChange}
          disabled={disabled}
        />
      )}
    </div>
  );
});

Tager.defaultProps = {
  items: [],
  setItems: () => {},
  placeholder: 'To add multiple options, put each option on a new line',
  showEmptyTextArea: true,
  disabled: false,
};

Tager.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isNew: PropTypes.bool,
    }),
  ),
  setItems: PropTypes.func,
  placeholder: PropTypes.string,
  showEmptyTextArea: PropTypes.bool,
  disabled: PropTypes.bool,
};

const TagerControl = React.memo(({ value, onChange, placeholder, showEmptyTextArea, disabled }) => (
  <Tager
    items={value}
    setItems={onChange}
    placeholder={placeholder}
    showEmptyTextArea={showEmptyTextArea}
    disabled={disabled}
  />
));

TagerControl.defaultProps = {
  value: [],
  onChange: () => {},
  placeholder: 'To add multiple options, put each option on a new line',
  showEmptyTextArea: true,
  disabled: false,
};

TagerControl.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isNew: PropTypes.bool,
    }),
  ),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  showEmptyTextArea: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default TagerControl;
