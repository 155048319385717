import { isNil } from 'lodash';

function set<T>(key: string, data: T): void {
  sessionStorage.setItem(key, JSON.stringify(data));
}

function get<T>(key: string): T | null {
  if (isNil(key)) {
    return null;
  }
  try {
    const storedData = sessionStorage.getItem(key);
    return isNil(storedData) ? null : (JSON.parse(storedData) as T);
  } catch {
    return null;
  }
}

function remove(key: string): void {
  sessionStorage.removeItem(key);
}

function clear(): void {
  sessionStorage.clear();
}

export default {
  clear,
  get,
  remove,
  set,
};
