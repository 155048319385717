import { post } from '../../../utils/backend';
import { toArray } from '../../../utils/helpers';

async function getFixedDistributionByDimension(
  filters,
  questions,
  settings,
  allFilters,
  dimensionsData,
  dimensionList,
) {
  const promices = [];
  const fixedDistributionByDimension = [];
  dimensionList
    .filter((dimension) => dimension.visibleAppendix)
    .forEach((dimension) => {
      const dimensionData = dimensionsData.find((d) => d.id === dimension.dimension_code);
      if (dimensionData) {
        const promise = new Promise((resolve) => {
          const subPromices = [
            post('/reporting/discussion_materials/answer_options_breakdown_by_dimension', {
              ...filters,
              filter: dimension.dimension_code,
            }),
          ];
          Promise.all(subPromices).then((data) => {
            if (toArray(data[0]?.questions).length) {
              fixedDistributionByDimension.push({
                questions: data[0]?.questions,
                id: dimension.dimension_code,
                dimensionId: dimension.id,
                name: dimension.display_name,
              });
            }
            resolve();
          });
        });
        promices.push(promise);
      }
    });
  await Promise.all(promices);
  return { fixedDistributionByDimension };
}

export default getFixedDistributionByDimension;
