import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Select } from 'antd';
import { sanitize } from 'dompurify';
import PropTypes from 'prop-types';

import { showError } from '../../../../utils/alerts';
import { post } from '../../../../utils/backend';
import Tager from '../../../Tager';

const { Option } = Select;

const StatementsCreator = React.memo(({ questions, dimensionValues, instanceId, onSave }) => {
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();

  function validateStatements(rule, value = []) {
    if (value.length > 0) {
      return Promise.resolve();
    }
    return Promise.reject('Enter at least one statement');
  }

  function onSubmit(values) {
    setSaving(true);
    const body = values.statements.map((item) => ({
      '@type': 'Statement',
      instance: { id: instanceId },
      question: { id: values.question },
      textval: item.text,
      dimensionValues: JSON.stringify(values.dimensionValues),
    }));
    post('/answers/bulkseed_statements?withoutReview=true', body)
      .then(() => {
        form.resetFields();
        initFields();
        onSave();
      })
      .catch((e) => showError(e.message))
      .finally(() => setSaving(false));
  }

  function initFields() {
    if (questions.length === 1) {
      form.setFieldsValue({ question: questions[0].id });
    }
    if (dimensionValues.length === 1) {
      form.setFieldsValue({ dimensionValues: [dimensionValues[0].id] });
    }
  }

  useEffect(() => {
    initFields();
  }, [questions, dimensionValues]);

  const showValues = dimensionValues.length > 1;

  return (
    <Form name="statements-form" onFinish={onSubmit} form={form}>
      <Row gutter={[24, 0]}>
        <Col xs={showValues ? 12 : 24}>
          <Form.Item name="question" rules={[{ required: true, message: ' ' }]}>
            <Select
              popupClassName="dropdown-wrap-lines"
              placeholder="Select question"
              showSearch
              optionFilterProp="searchtext"
            >
              {questions.map((item) => (
                <Option
                  value={item.id}
                  key={item.id}
                  searchtext={sanitize(item.text, { ALLOWED_TAGS: [] })}
                >
                  <div dangerouslySetInnerHTML={{ __html: sanitize(item.text) }} />
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={showValues ? 12 : 0}>
          <Form.Item
            name="dimensionValues"
            rules={[{ required: true, message: ' ' }]}
            hidden={!showValues}
          >
            <Select
              placeholder="Select dimension values"
              mode="multiple"
              maxTagTextLength={20}
              optionFilterProp="children"
            >
              {dimensionValues.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="statements" rules={[{ validator: validateStatements }]}>
        <Tager />
      </Form.Item>
      <Form.Item className="align-right">
        <Button type="primary" htmlType="submit" loading={saving}>
          Add
        </Button>
      </Form.Item>
    </Form>
  );
});

StatementsCreator.defaultProps = {
  questions: [],
  dimensionValues: [],
  instanceId: undefined,
  onSave: () => {},
};

StatementsCreator.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string,
    }),
  ),
  dimensionValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  instanceId: PropTypes.number,
  onSave: PropTypes.func,
};

export default StatementsCreator;
