import { FC, memo } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Input } from 'antd';

import './styles.scss';

interface SearchProps {
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  className?: string;
}

const Search: FC<SearchProps> = memo(
  ({ value, onChange, placeholder = 'Search text', className }) => (
    <Input
      className={`search-input ${className ?? ''}`}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      onKeyDown={(e) => e.stopPropagation()}
      onFocus={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
      prefix={<Icon icon={faSearch} />}
      allowClear
    />
  ),
);

export default Search;
