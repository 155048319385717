import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { faFileExport, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Button, Col, Dropdown, Modal, Row, Select, Table, Tooltip } from 'antd';
import { first, forIn, map, startCase } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  DEFAULT_API_FORMAT,
  DEFAULT_UI_DATE_FORMAT,
  DEI_STATUSES,
  DIMENSION_STATUSES_CODES,
  EMAIL_TYPES,
  MANAGER_REVIEW_STATUS,
  STATUSES,
  SURVEY_TYPE,
} from '../../../constants';
import { useStandardDimensions } from '../../../store/standardDimensions';
import { UserContext } from '../../../store/user';
import { fetchUserDimensions, useUserDimensions } from '../../../store/userDimensions';
import { showError, showSuccess } from '../../../utils/alerts';
import { get, post, put } from '../../../utils/backend';
import { cloneDeep, showTableTotal, toArray, toObject, toString } from '../../../utils/helpers';
import { export360ManagersProgress, exportRespondentsFile } from '../../../utils/xlsx';
import Search from '../../Search';
import UserModal from '../User';

import './styles.scss';

const { Option } = Select;

const Respondents = React.memo(
  ({ visible, onCancel, instanceId, surveyId, surveyName, surveyType }) => {
    const [loading, setLoading] = useState(true);
    const [exporting, setExporting] = useState(false);
    const [users, setUsers] = useState([]);
    const [managers, setManagers] = useState([]);
    const [sortedUsers, setSortedUSers] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [filters, setFilters] = useState({});
    const [dimensions, setDimensions] = useState([]);
    const [dimensionsLoading, setDimensionsLoading] = useState(true);
    const [dimension1, setDimension1] = useState();
    const [dimension2, setDimension2] = useState();
    const [userModalSettings, setUserModalSettings] = useState({});
    const [surveyEmails, setSurveyEmails] = useState([]);
    const [emailsLoading, setEmailsLoading] = useState();
    const [emailsModalSettings, setEmailsModalSettings] = useState({});
    const [selectedEmailId, setSelectedEmailId] = useState();
    const [dataView, setDataView] = useState('360_participants');
    const { user } = useContext(UserContext);
    const {
      standardDimensionCodeToOrgDimensionId,
      loading: standardDimensionLoading,
      standardDimensionCodeToOrgDimensionCode,
    } = useStandardDimensions();

    const MANAGER_REVIEW_STATUSES = [];
    forIn(MANAGER_REVIEW_STATUS, (val, key) => {
      MANAGER_REVIEW_STATUSES.push({ value: val, text: startCase(key) });
    });

    const VIEW_TYPE = {
      PARTICIPANTS_360: '360_participants',
      MANAGER_REVIEW_PARTICIPANTS: 'manager_review',
    };

    const USER_DIMENSIONS = {
      RELATIONSHIP: 'relationship',
    };

    const { isSuperAdmin, organizationName, isAdmin } = user;

    function getFullName(item) {
      return `${item?.firstName || ''} ${item?.lastName || ''}`.trim();
    }

    function getManagerFullName(item) {
      return `${item?.managerFirstName || ''} ${item?.managerLastName || ''}`.trim();
    }

    function getReviewFullName(item) {
      return `${item?.targetUserFirstName || ''} ${item?.targetUserLastName || ''}`.trim();
    }

    function onChange(pagination, filtersInfo, sorter, extra) {
      setFilters(filtersInfo);
      setSortedUSers(extra.currentDataSource);
    }

    function filterUser(item, value) {
      return (
        getFullName(item).toLowerCase().includes(value.toLowerCase()) ||
        item.email.toLowerCase().includes(value.toLowerCase())
      );
    }

    function managerReviewFilterUser(item) {
      return (
        getManagerFullName(item).toLowerCase().includes(searchText.toLowerCase()) ||
        getReviewFullName(item).toLowerCase().includes(searchText.toLowerCase()) ||
        item.managerEmail.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    async function getRespondents() {
      setLoading(true);
      let url =
        SURVEY_TYPE.PARENT_360 === surveyType
          ? `/instances/${surveyId}/comm_respondents`
          : `/instances/${surveyId}/respondents`;
      if (dimension1) {
        url += `?dimension1=${dimension1}`;
        if (dimension2) {
          url += `&dimension2=${dimension2}`;
        }
      }
      const managerAnswers = await get(`/answers/${surveyId}/get_managers_answers`);
      const reviews = Object.groupBy(managerAnswers, ({ reviewId }) => reviewId);

      const request = get;
      request(url)
        .then((res) => {
          if (toArray(res).length) {
            setUsers(
              res.map((item) => ({
                ...item,
                token: isSuperAdmin || (!isSurveyType360 && isAdmin) ? item.token : undefined,
                key: `${item.userId}${item.surveyInstanceId || instanceId}`,
              })),
            );
            setManagers(
              Object.values(reviews).map((item) => ({
                reviewer: res.find((r) => r.userId === first(Object.values(item)).targetUserId),
                child: item,
                email: first(Object.values(item))?.managerEmail,
                token:
                  isSuperAdmin ||
                  ([SURVEY_TYPE.CHILD_360, SURVEY_TYPE.PARENT_360].indexOf(surveyType) < 0 &&
                    isAdmin)
                    ? item.token
                    : undefined,
                key: `${first(Object.values(item)).targetUserId}_${
                  first(Object.values(item)).managerId
                }`,
              })),
            );
          } else {
            setUsers([]);
          }
        })
        .catch((e) => showError(e.message))
        .finally(() => setLoading(false));
    }

    function getSurveyTakingUrl() {
      return isSurveyTypeDeiOrEsg ? user.deiSurveyTakingUrl : user.surveyTakingUrl;
    }

    async function exportManagers(rawData, surveyname) {
      setExporting(true);

      let data = rawData;
      if (searchText.length) {
        data = data.filter((item) => managerReviewFilterUser(first(item?.child)));
      }
      if (filters.reviewStatus) {
        data = data.filter((item) =>
          filters.reviewStatus.includes(first(item?.child).reviewStatus),
        );
      }
      const result = map(data, 'child').flat();
      export360ManagersProgress(result, `${surveyname} - Managers progress`);
      setExporting(false);
    }

    async function exportRespondents(full = false) {
      setExporting(true);
      let dimensionsData = [];
      if (full) {
        try {
          dimensionsData = await get(
            `/instances/${surveyId}/${instanceId}/respondents_by_dimension`,
          );
        } catch (e) {
          showError(e.message);
        }
      }
      exportRespondentsFile(
        sortedUsers.filter((item) => filterUser(item, searchText)),
        toObject(dimensions.find((item) => item.dimension_code === dimension1)).display_name,
        toObject(dimensions.find((item) => item.dimension_code === dimension2)).display_name,
        getSurveyTakingUrl(),
        isSuperAdmin,
        dimensionsData,
        `${organizationName} - ${surveyName} - Respondents`,
        surveyType,
      );
      setExporting(false);
    }

    function onSendReminderOk(item) {
      return get(
        `/instances/${instanceId || item?.surveyInstanceId}/send_remainder_emails/${
          item?.userId
        }?reminderId=${selectedEmailId}`,
      )
        .then(() => {
          showSuccess(`A reminder was sent to ${item?.email}`);
          const list = cloneDeep(users);
          const current = list.find(
            (i) => i.userId === item?.userId && i.surveyInstanceId === item?.surveyInstanceId,
          );
          current.reminderDate = moment().format(DEFAULT_API_FORMAT);
          setUsers(list);

          const managersList = cloneDeep(managers);
          const currentManager = list.find(
            (i) => i.userId === item?.userId && i.surveyInstanceId === item?.surveyInstanceId,
          );
          currentManager.reminderDate = moment().format(DEFAULT_API_FORMAT);
          setManagers(managersList);
          setEmailsModalSettings({});
        })
        .catch((e) => showError(e.message));
    }

    function sendReminder(item) {
      if (toArray(surveyEmails).filter((i) => i.typeId === EMAIL_TYPES.REMINDER).length > 1) {
        setEmailsModalSettings({
          visible: true,
          item,
        });
      } else {
        Modal.confirm({
          title: `Send reminder to ${getFullName(item)}?`,
          onOk() {
            return onSendReminderOk(item);
          },
        });
      }
    }

    function sendSurvey(item) {
      Modal.confirm({
        title: `Send survey to ${getFullName(item)}?`,
        onOk() {
          return get(
            `/instances/${instanceId || item.surveyInstanceId}/send_emails/${item.userId}?emailId=${
              surveyEmails.filter((i) => i.typeId === EMAIL_TYPES.INITIAL)[0]?.id
            }`,
          )
            .then(() => {
              showSuccess(`A survey was sent to ${item.email}`);
              const list = cloneDeep(users);
              const current = list.find(
                (i) => i.userId === item.userId && i.surveyInstanceId === item.surveyInstanceId,
              );
              current.dateSent = moment().format(DEFAULT_API_FORMAT);
              setUsers(list);
            })
            .catch((e) => showError(e.message));
        },
      });
    }

    function resetResponses(item) {
      Modal.confirm({
        title:
          "Are you sure you want to remove all user's responses? This option should be used only in exceptional situations.",
        onOk() {
          return post(`/instances/${instanceId}/reset_response/${item.userId}`)
            .then(() => {
              showSuccess('Survey responses were deleted.');
              getRespondents();
            })
            .catch((e) => showError(e.message));
        },
      });
    }

    function editUser(item) {
      setUserModalSettings({
        visible: true,
        id: item.userId,
      });
    }

    function sendReview(item) {
      get(`/survey_managers/send_reminder/${item.reviewId}`)
        .then(() => {
          showSuccess('Manager review sent');
        })
        .catch((e) => showError(e.message));
    }

    function updateStatus(item, status) {
      put('/users/property', { userIds: [item.userId], status })
        .then(() => {
          showSuccess('User status was updated');
          getRespondents();
        })
        .catch((e) => showError(e.message));
    }

    function getManagersActionsMenu(item) {
      const hasInstance = instanceId || item.surveyInstanceId;
      const hasReview = first(item.child)?.reviewId;
      return {
        onClick: ({ key }) => {
          if (key === 'sendSurvey') {
            return sendReview(first(item.child));
          }
          if (key === 'editUser') {
            setUserModalSettings({
              visible: true,
              id: first(item.child)?.managerId,
            });
          }
        },
        items: [
          hasReview ? { label: 'Resend manager review', key: 'sendSurvey' } : null,
          hasInstance ? { type: 'divider' } : null,
          { label: 'Edit user or email', key: 'editUser' },
        ].filter(Boolean),
      };
    }

    function getActionsMenu(item) {
      const hasInstance = instanceId || item.surveyInstanceId;
      return {
        onClick: ({ key }) => {
          if (key === 'sendReminder') {
            return sendReminder(item);
          }
          if (key === 'sendSurvey') {
            return sendSurvey(item);
          }
          if (key === 'resetResponses') {
            return resetResponses(item);
          }
          if (key === 'editUser') {
            return editUser(item);
          }
          if (key === 'makeInactive') {
            return updateStatus(item, 2);
          }
          if (key === 'makeActive') {
            return updateStatus(item, 1);
          }
        },
        items: [
          isAdmin && item.token
            ? {
                label: (
                  <a href={`${getSurveyTakingUrl()}${item.token}`} target="_blank" rel="noreferrer">
                    Respond on behalf of user
                  </a>
                ),
                key: 'respondOnUserBehalf',
              }
            : null,
          isAdmin && item.token && isSurveyTypeDeiOrEsg
            ? {
                label: (
                  <a
                    href={`${getSurveyTakingUrl()}${item.token}&mode=readonly`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Survey (Read Only)
                  </a>
                ),
                key: 'viewSurveyRO',
              }
            : null,
          hasInstance && surveyEmails.length
            ? { label: 'Send reminder', key: 'sendReminder' }
            : null,
          hasInstance && surveyEmails.length
            ? { label: item.dateSent ? 'Resend survey' : 'Send survey', key: 'sendSurvey' }
            : null,
          isSuperAdmin && item.responseDate && !isSurveyTypeDeiOrEsg
            ? { label: 'Reset responses', key: 'resetResponses' }
            : null,
          hasInstance ? { type: 'divider' } : null,
          { label: 'Edit user or email', key: 'editUser' },
          item.status === 1 ? { label: 'Make inactive', key: 'makeInactive' } : null,
          item.status === 2 ? { label: 'Make active', key: 'makeActive' } : null,
        ].filter(Boolean),
      };
    }

    function closeUserModal() {
      setUserModalSettings({});
    }

    function onUserSave() {
      closeUserModal();
      getRespondents();
    }

    const isSurveyTypeDeiOrEsg = useMemo(() => {
      return surveyType === SURVEY_TYPE.DEI || surveyType === SURVEY_TYPE.ESG;
    }, [surveyType]);

    const isSurveyType360 = useMemo(() => {
      return surveyType === SURVEY_TYPE.PARENT_360 || surveyType === SURVEY_TYPE.CHILD_360;
    }, [surveyType]);

    useEffect(() => {
      if (!visible) {
        setLoading(true);
        setDimensionsLoading(true);
        setUsers([]);
        setDimensions([]);
        setDimension1(undefined);
        setDimension2(undefined);
        closeUserModal();
        setSurveyEmails([]);
        setSelectedEmailId();
        setEmailsModalSettings({});
      }
    }, [visible]);

    useEffect(() => {
      if (!instanceId || !surveyId || !visible || standardDimensionLoading) return;
      fetchUserDimensions(true)
        .then((list) => {
          if (list?.length) {
            const nonConfidentialDimensions = list.filter(
              (item) => item.status !== DIMENSION_STATUSES_CODES.CONFIDENTIAL,
            );
            setDimension1(
              surveyType === SURVEY_TYPE.CHILD_360
                ? USER_DIMENSIONS.RELATIONSHIP
                : standardDimensionCodeToOrgDimensionCode?.department,
            );
            setDimension2(standardDimensionCodeToOrgDimensionCode?.emprole);
            setDimensions(nonConfidentialDimensions);
          } else {
            setDimensions([]);
          }
        })
        .catch((e) => showError(e.message))
        .finally(() => setDimensionsLoading(false));

      get(`/surveys/${surveyId}/survey_emails_list`)
        .then((res) => {
          setSurveyEmails(toArray(res));
          setSelectedEmailId(toArray(res).filter((i) => i.typeId === EMAIL_TYPES.REMINDER)[0]?.id);
        })
        .catch((e) => showError(e.message))
        .finally(() => setEmailsLoading(false));
    }, [instanceId, surveyId, visible, standardDimensionLoading]);

    useEffect(() => {
      if (!visible || dimensionsLoading) return;
      getRespondents();
    }, [dimension1, dimension2, visible, dimensions, dimensionsLoading]);

    useEffect(() => setSortedUSers(users), [users]);

    useEffect(() => {
      // TODO: dei progress
    }, [users, surveyId, instanceId, surveyType]);

    const deiColumns = [
      {
        title: 'Company name',
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        ellipsis: {
          showTitle: false,
        },
        render: (value, item) => (
          <Tooltip placement="topLeft" title={getFullName(item)}>
            {getFullName(item)}
          </Tooltip>
        ),
        sorter: (a, b) => getFullName(a).trim().localeCompare(getFullName(b).trim()),
        onFilter: (value, item) => filterUser(item, value),
        filteredValue: [searchText],
      },
      {
        title: 'Status',
        dataIndex: 'responseDate',
        key: 'status',
        width: '9%',
        filters: DEI_STATUSES.map((item) => ({ value: item.value, text: item.label })),
        onFilter: (value, item) => {
          if (value === 'in_progress') {
            return item.responseDate !== null && item.submitedDate === null;
          }
          if (value === 'not_started') {
            return item.responseDate === null;
          }
          if (value === 'submitted') {
            return item.submitedDate !== null;
          }
        },
        render: (value, item) => {
          let label = 'In progress';
          if (value === null) {
            label = 'Not started';
          } else if (item.submitedDate !== null) {
            label = 'Submitted';
          }
          return label;
        },
        filteredValue: filters.status || null,
      },
      {
        title: 'Last updated',
        dataIndex: 'responseDate',
        key: 'responseDate',
        width: '10%',
        render: (value) => (value ? moment(value).format(DEFAULT_UI_DATE_FORMAT) : ''),
        sorter: (a, b) =>
          (moment(a.responseDate).valueOf() || 0) - (moment(b.responseDate).valueOf() || 0),
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'groups',
        width: '6%',
        align: 'center',
        render: (id, item) => (
          <Dropdown menu={getActionsMenu(item)} placement="bottomRight">
            <Button size="small" icon={<Icon icon={faWrench} />} />
          </Dropdown>
        ),
      },
    ];

    const columns = [
      {
        title: 'Full name',
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        ellipsis: {
          showTitle: false,
        },
        render: (value, item) => (
          <Tooltip placement="topLeft" title={getFullName(item)}>
            {getFullName(item)}
          </Tooltip>
        ),
        sorter: (a, b) => getFullName(a).trim().localeCompare(getFullName(b).trim()),
        onFilter: (value, item) => filterUser(item, value),
        filteredValue: [searchText],
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: '15%',
        ellipsis: {
          showTitle: false,
        },
        render: (text) => (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ),
        sorter: (a, b) => a.email.trim().localeCompare(b.email.trim()),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '9%',
        filters: STATUSES.map((item) => ({ value: item.value, text: item.label })),
        onFilter: (value, item) => item.status === value,
        render: (value) => (STATUSES.find((item) => item.value === value) || {}).label,
        sorter: (a, b) => b.status - a.status,
        filteredValue: filters.status || null,
      },
    ];

    const managerTableColumns = [
      {
        title: 'Manager name',
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        ellipsis: {
          showTitle: false,
        },
        render: (value, item) => (
          <Tooltip placement="topLeft" title={getManagerFullName(first(item?.child))}>
            {getManagerFullName(first(item?.child))}
          </Tooltip>
        ),
        sorter: (a, b) =>
          getManagerFullName(first(a?.child)).localeCompare(getManagerFullName(first(b?.child))),
        onFilter: (value, item) => managerReviewFilterUser(first(item?.child), value),
        filteredValue: [searchText],
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: '20%',
        ellipsis: {
          showTitle: false,
        },
        render: (text) => (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ),
        sorter: (a, b) => a.email.trim().localeCompare(b.email.trim()),
      },
      {
        title: 'Feedback recipient',
        dataIndex: 'review',
        key: 'review',
        width: '20%',
        ellipsis: {
          showTitle: false,
        },
        render: (value, item) => (
          <Tooltip placement="topLeft" title={getReviewFullName(first(item?.child))}>
            {getReviewFullName(first(item?.child))}
          </Tooltip>
        ),
        sorter: (a, b) =>
          getReviewFullName(first(a?.child)).localeCompare(getReviewFullName(first(b?.child))),
        onFilter: (value, item) => managerReviewFilterUser(first(item?.child), value),
        filteredValue: [searchText],
      },
      {
        title: 'Review status',
        dataIndex: 'reviewStatus',
        key: 'reviewStatus',
        width: '10%',
        filters: MANAGER_REVIEW_STATUSES,
        onFilter: (value, item) => first(item?.child).reviewStatus === value,
        render: (value, rowItem) =>
          (
            MANAGER_REVIEW_STATUSES.find(
              (item) => item.value === first(rowItem?.child).reviewStatus,
            ) || {}
          )?.text,
        sorter: (a, b) => first(b.child).reviewStatus - first(a.child).reviewStatus,
        filteredValue: filters.reviewStatus || null,
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'groups',
        width: '6%',
        align: 'center',
        render: (id, item) => (
          <Dropdown menu={getManagersActionsMenu(item)} placement="bottomRight">
            <Button size="small" icon={<Icon icon={faWrench} />} />
          </Dropdown>
        ),
      },
    ];
    if (SURVEY_TYPE.PARENT_360 !== surveyType && dimension1) {
      columns.push({
        title: toObject(dimensions.find((item) => item.dimension_code === dimension1)).display_name,
        dataIndex: 'dimension1',
        key: 'dimension1',
        width: '10%',
        ellipsis: {
          showTitle: false,
        },
        render: (text) => (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ),
        sorter: (a, b) =>
          toString(a.dimension1).trim().localeCompare(toString(b.dimension1).trim()),
      });
    }
    if (!isSurveyType360 && dimension2) {
      columns.push({
        title: toObject(dimensions.find((item) => item.dimension_code === dimension2)).display_name,
        dataIndex: 'dimension2',
        key: 'dimension2',
        width: '10%',
        ellipsis: {
          showTitle: false,
        },
        render: (text) => (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ),
        sorter: (a, b) =>
          toString(a.dimension2).trim().localeCompare(toString(b.dimension2).trim()),
      });
    }
    columns.push(
      {
        title: 'Sent',
        dataIndex: 'dateSent',
        key: 'dateSent',
        width: '10%',
        render: (value) => (value ? moment(value).format(DEFAULT_UI_DATE_FORMAT) : ''),
        sorter: (a, b) => (moment(a.dateSent).valueOf() || 0) - (moment(b.dateSent).valueOf() || 0),
      },
      {
        title: 'Reminder',
        dataIndex: 'reminderDate',
        key: 'reminderDate',
        width: '10%',
        render: (value) => (value ? moment(value).format(DEFAULT_UI_DATE_FORMAT) : ''),
        sorter: (a, b) =>
          (moment(a.reminderDate).valueOf() || 0) - (moment(b.reminderDate).valueOf() || 0),
      },
      {
        title: 'Response',
        dataIndex: 'responseDate',
        key: 'responseDate',
        width: '10%',
        render: (value) => (value ? moment(value).format(DEFAULT_UI_DATE_FORMAT) : ''),
        sorter: (a, b) =>
          (moment(a.responseDate).valueOf() || 0) - (moment(b.responseDate).valueOf() || 0),
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'groups',
        width: '6%',
        align: 'center',
        render: (id, item) => (
          <Dropdown menu={getActionsMenu(item)} placement="bottomRight">
            <Button size="small" icon={<Icon icon={faWrench} />} />
          </Dropdown>
        ),
      },
    );

    return (
      <Modal
        className="respondents-modal"
        open={visible}
        onCancel={onCancel}
        footer={null}
        width={1200}
        style={{ minWidth: '1200px' }}
        afterClose={() => {
          setSearchText('');
          setDataView(VIEW_TYPE.PARTICIPANTS_360);
        }}
      >
        <Row gutter={[8, 0]} justify="space-between" style={{ marginTop: '25px' }}>
          <Col xs={8}>
            <Search
              style={{ margin: 0 }}
              value={searchText}
              onChange={setSearchText}
              placeholder={`Search ${isSurveyTypeDeiOrEsg ? 'company' : 'users'}`}
            />
          </Col>

          <Col sm={16}>
            <Row gutter={[8, 0]} justify="end" align="middle">
              <Col sm={2} className={isSurveyTypeDeiOrEsg || managers.length === 0 ? 'hidden' : ''}>
                Display
              </Col>
              <Col sm={4} className={isSurveyTypeDeiOrEsg || isSurveyType360 ? 'hidden' : ''}>
                <Select
                  className="w-100"
                  loading={dimensionsLoading}
                  onChange={setDimension1}
                  value={dimension1}
                  placeholder="Select dimension"
                  popupMatchSelectWidth={200}
                  showSearch
                  options={dimensions
                    .filter((item) => item.dimension_code !== dimension2)
                    .map((item) => ({
                      label: item.display_name,
                      value: item.dimension_code,
                    }))}
                />
              </Col>
              <Col sm={6} className={isSurveyTypeDeiOrEsg || isSurveyType360 ? 'hidden' : ''}>
                <Select
                  className="w-100"
                  loading={dimensionsLoading}
                  onChange={setDimension2}
                  value={dimension2}
                  placeholder="Select dimension"
                  popupMatchSelectWidth={200}
                  showSearch
                  options={dimensions
                    .filter((item) => item.dimension_code !== dimension1)
                    .map((item) => ({
                      label: item.display_name,
                      value: item.dimension_code,
                    }))}
                />
              </Col>
              <Col
                sm={7}
                className={
                  isSurveyTypeDeiOrEsg || SURVEY_TYPE.PARENT_360 !== surveyType ? 'hidden' : ''
                }
              >
                {managers.length > 0 && (
                  <Select
                    className="w-100"
                    value={dataView}
                    onChange={setDataView}
                    options={[
                      { value: VIEW_TYPE.PARTICIPANTS_360, label: '360 participants' },
                      {
                        value: VIEW_TYPE.MANAGER_REVIEW_PARTICIPANTS,
                        label: 'Manager review participants',
                      },
                    ]}
                  />
                )}
              </Col>
              <Col>
                <Button
                  type="primary"
                  disabled={loading}
                  loading={exporting}
                  icon={<Icon icon={faFileExport} className="btn-icon" />}
                  onClick={() =>
                    dataView === VIEW_TYPE.PARTICIPANTS_360
                      ? exportRespondents(true)
                      : exportManagers(managers, surveyName)
                  }
                >
                  Export
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        {dataView === VIEW_TYPE.PARTICIPANTS_360 && (
          <Table
            size="small"
            onChange={onChange}
            loading={loading || dimensionsLoading || emailsLoading}
            dataSource={users}
            columns={isSurveyTypeDeiOrEsg ? deiColumns : columns}
            showSorterTooltip={false}
            rowKey="key"
            pagination={{ showSizeChanger: true, showTotal: showTableTotal, defaultPageSize: 20 }}
          />
        )}

        {dataView === VIEW_TYPE.MANAGER_REVIEW_PARTICIPANTS && (
          <Table
            size="small"
            onChange={onChange}
            loading={loading || dimensionsLoading || emailsLoading}
            dataSource={managers}
            columns={managerTableColumns}
            showSorterTooltip={false}
            rowKey="key"
            pagination={{ showSizeChanger: true, showTotal: showTableTotal, defaultPageSize: 20 }}
          />
        )}
        <UserModal
          visible={userModalSettings.visible}
          id={userModalSettings.id}
          onCancel={closeUserModal}
          onSave={onUserSave}
          surveyId={surveyId}
        />
        <Modal
          title={`Send reminder to ${getFullName(emailsModalSettings?.item)}?`}
          open={emailsModalSettings?.visible}
          onOk={() => onSendReminderOk(emailsModalSettings?.item)}
          okButtonProps={{ disabled: !selectedEmailId }}
          onCancel={() => setEmailsModalSettings({})}
        >
          <p>Please select reminder</p>
          <Select
            value={selectedEmailId}
            onChange={setSelectedEmailId}
            placeholder="Select reminder"
            popupMatchSelectWidth={200}
          >
            {surveyEmails
              .filter((i) => i.typeId === EMAIL_TYPES.REMINDER)
              .map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
          </Select>
        </Modal>
      </Modal>
    );
  },
);

Respondents.defaultProps = {
  visible: false,
  onCancel: () => {},
  surveyId: undefined,
  instanceId: undefined,
  surveyName: '',
  surveyType: undefined,
};

Respondents.propTypes = {
  surveyName: PropTypes.string,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  surveyId: PropTypes.number,
  instanceId: PropTypes.number,
  surveyType: PropTypes.string,
};

export default Respondents;
