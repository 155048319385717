import { FetcherArgs } from './fetchers';

export interface SlideConfigUI {
  visible: boolean;
  priority: number;
}

export interface SlidesConfigUI {
  [key: string]: SlideConfigUI;
}

export type Fetcher = (args: FetcherArgs) => Promise<any>;

export enum LoadingStateEnum {
  NOT_STARTED = 'notStarted',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface DataFunctionUI {
  fetcher: Fetcher;
  priority: number;
  loadingState: LoadingStateEnum;
  data: any | null;
  error: Error | null;
}
