/* eslint-disable max-lines */
import * as echarts from 'echarts/core';

// TODO: sync with antd
echarts.registerTheme('entromy', {
  backgroundColor: 'rgba(255,255,255,0)',
  bar: {
    itemStyle: {
      barBorderColor: '#ccc',
      barBorderWidth: 0,
    },
  },
  boxplot: {
    itemStyle: {
      borderColor: '#ccc',
      borderWidth: 0,
    },
  },
  candlestick: {
    itemStyle: {
      borderColor: '#d0648a',
      borderColor0: '#22c3aa',
      borderWidth: '1',
      color: '#d0648a',
      color0: 'transparent',
    },
  },
  categoryAxis: {
    axisLabel: {
      color: '#999999',
      show: true,
    },
    axisLine: {
      lineStyle: {
        color: '#cccccc',
      },
      show: true,
    },
    axisTick: {
      lineStyle: {
        color: '#333',
      },
      show: false,
    },
    splitArea: {
      areaStyle: {
        color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
      },
      show: false,
    },
    splitLine: {
      lineStyle: {
        color: ['#eeeeee'],
      },
      show: true,
    },
  },
  dataZoom: {
    backgroundColor: 'rgba(255,255,255,0)',
    dataBackgroundColor: 'rgba(222,222,222,1)',
    fillerColor: 'rgba(114,230,212,0.25)',
    handleColor: '#cccccc',
    handleSize: '100%',
    textStyle: {
      color: '#999999',
    },
  },
  funnel: {
    itemStyle: {
      borderColor: '#ccc',
      borderWidth: 0,
    },
  },
  gauge: {
    itemStyle: {
      borderColor: '#ccc',
      borderWidth: 0,
    },
  },
  geo: {
    emphasis: {
      itemStyle: {
        areaColor: 'rgba(34,195,170,0.25)',
        borderColor: '#22c3aa',
        borderWidth: 1,
      },
      label: {
        color: '#349e8e',
      },
    },
    itemStyle: {
      areaColor: '#eeeeee',
      borderColor: '#999999',
      borderWidth: 0.5,
    },
    label: {
      color: '#28544e',
    },
  },
  graph: {},
  legend: {
    textStyle: {
      color: '#999999',
    },
  },
  line: {
    itemStyle: {
      borderWidth: '2',
    },
    lineStyle: {
      width: '3',
    },
    smooth: false,
    symbol: 'emptyCircle',
    symbolSize: '8',
  },
  logAxis: {
    axisLabel: {
      color: '#999999',
      show: true,
    },
    axisLine: {
      lineStyle: {
        color: '#cccccc',
      },
      show: true,
    },
    axisTick: {
      lineStyle: {
        color: '#333',
      },
      show: false,
    },
    splitArea: {
      areaStyle: {
        color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
      },
      show: false,
    },
    splitLine: {
      lineStyle: {
        color: ['#eeeeee'],
      },
      show: true,
    },
  },
  map: {
    emphasis: {
      itemStyle: {
        areaColor: 'rgba(34,195,170,0.25)',
        borderColor: '#22c3aa',
        borderWidth: 1,
      },
      label: {
        color: '#349e8e',
      },
    },
    itemStyle: {
      areaColor: '#eeeeee',
      borderColor: '#999999',
      borderWidth: 0.5,
    },
    label: {
      color: '#28544e',
    },
  },
  markPoint: {
    emphasis: {
      label: {
        color: '#ffffff',
      },
    },
    label: {
      color: '#ffffff',
    },
  },
  parallel: {
    itemStyle: {
      borderColor: '#ccc',
      borderWidth: 0,
    },
  },
  pie: {
    itemStyle: {
      borderColor: '#ccc',
      borderWidth: 0,
    },
  },
  radar: {
    itemStyle: {
      borderWidth: '2',
    },
    lineStyle: {
      width: '3',
    },
    smooth: false,
    symbol: 'emptyCircle',
    symbolSize: '8',
  },
  sankey: {
    itemStyle: {
      borderColor: '#ccc',
      borderWidth: 0,
    },
  },
  scatter: {
    itemStyle: {
      borderColor: '#ccc',
      borderWidth: 0,
    },
  },
  timeAxis: {
    axisLabel: {
      color: '#999999',
      show: true,
    },
    axisLine: {
      lineStyle: {
        color: '#cccccc',
      },
      show: true,
    },
    axisTick: {
      lineStyle: {
        color: '#999999',
      },
      show: false,
    },
    splitArea: {
      areaStyle: {
        color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
      },
      show: false,
    },
    splitLine: {
      lineStyle: {
        color: ['#eeeeee'],
      },
      show: true,
    },
  },
  timeline: {
    checkpointStyle: {
      borderColor: '#3cebd2',
      color: '#4ea397',
    },
    controlStyle: {
      borderColor: '#4ea397',
      borderWidth: 0.5,
      color: '#4ea397',
    },
    emphasis: {
      controlStyle: {
        borderColor: '#4ea397',
        borderWidth: 0.5,
        color: '#4ea397',
      },
      itemStyle: {
        color: '#4ea397',
      },
      label: {
        color: '#4ea397',
      },
    },
    itemStyle: {
      borderWidth: 1,
      color: '#4ea397',
    },
    label: {
      color: '#4ea397',
    },
    lineStyle: {
      color: '#4ea397',
      width: 1,
    },
  },
  title: {
    subtextStyle: {
      color: '#999999',
    },
    textStyle: {
      color: '#666666',
    },
  },
  toolbox: {
    emphasis: {
      iconStyle: {
        borderColor: '#666666',
      },
    },
    iconStyle: {
      borderColor: '#999999',
    },
  },
  tooltip: {
    axisPointer: {
      crossStyle: {
        color: '#cccccc',
        width: 1,
      },
      lineStyle: {
        color: '#cccccc',
        width: 1,
      },
    },
  },
  valueAxis: {
    axisLabel: {
      color: '#999999',
      show: true,
    },
    axisLine: {
      lineStyle: {
        color: '#cccccc',
      },
      show: true,
    },
    axisTick: {
      lineStyle: {
        color: '#333',
      },
      show: false,
    },
    splitArea: {
      areaStyle: {
        color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
      },
      show: false,
    },
    splitLine: {
      lineStyle: {
        color: ['#eeeeee'],
      },
      show: true,
    },
  },
  visualMap: {
    color: ['#d0648a', '#22c3aa', '#adfff1'],
  },
});
