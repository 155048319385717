import { createContext, useEffect, useMemo, useState } from 'react';
import { noop } from 'lodash';

import { TOKEN_STORAGE, USER_STORAGE } from '../../constants';
import { createUserFromResponse, removeUserInfo, UserUI } from '../../utils/auth';
import { get } from '../../utils/backend';
import { toString } from '../../utils/helpers';
import ss from '../../utils/ss';

interface UserContextProps {
  user: Partial<UserUI>; // TODO: should be UserUI | null
  setUser: (user: Partial<UserUI>) => void;
  reloadUser: () => void;
  logout: () => Promise<void>;
  updateUserField: <K extends keyof UserUI>(fieldName: K, newValue: UserUI[K]) => void;
}

export const UserContext = createContext<UserContextProps>({
  logout: () => Promise.resolve(),
  reloadUser: noop,
  setUser: noop,
  updateUserField: noop,
  user: {},
});

interface UserProviderProps {
  children: React.ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<Partial<UserUI>>({});
  const [reloadToken, setReloadToken] = useState(0);

  function reloadUser(): void {
    setReloadToken((prevReloadToken) => prevReloadToken + 1);
  }

  function updateUser(userData: Partial<UserUI>) {
    ss.set(TOKEN_STORAGE, userData.accessToken);
    ss.set(USER_STORAGE, userData);
    setUser(userData);
  }

  const updateUserField = <K extends keyof UserUI>(fieldName: K, newValue: UserUI[K]): void => {
    setUser((oldUser) => ({ ...oldUser, [fieldName]: newValue }));
  };

  async function logout(): Promise<void> {
    setUser({});
    removeUserInfo();
    try {
      await get('/users/logout');
      Intercom('shutdown');
    } catch (err) {
      //
    }
  }

  async function refetchUser(): Promise<void> {
    try {
      const resp = await get('/users/login_refresh');
      updateUser(createUserFromResponse(resp));
    } catch (err) {
      //
    }
  }

  useEffect(() => {
    const userInfo = ss.get<UserUI>(USER_STORAGE);
    if (userInfo) {
      setUser(userInfo);
      refetchUser();
    }
  }, [reloadToken]);

  useEffect(() => {
    if (toString(user.userId).length && toString(user.intercomUserHash).length) {
      Intercom('boot', {
        app_id: 'g6k3xqrl',
        company: {
          company_id: user.baseOrgId ?? undefined,
          name: user.baseOrgName ?? '',
        },
        email: user.email ?? undefined,
        user_hash: user.intercomUserHash ?? undefined,
      });
    }
  }, [user.userId, user.intercomUserHash]);

  const value = useMemo(
    () => ({
      logout,
      reloadUser,
      setUser: updateUser,
      updateUserField,
      user,
    }),
    [user, updateUser, logout, reloadUser, updateUserField],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
