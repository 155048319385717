/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dictionary } from 'lodash';

import { CardCategoryRowRes } from '../../components/Dashboard/CardCategory';
import { CardGaugeENPSRes } from '../../components/Dashboard/CardGaugeENPS';
import { CardGaugeOHBRes } from '../../components/Dashboard/CardGaugeOHB';
import { CardGaugeParticipationRes } from '../../components/Dashboard/CardGaugeParticipation';
import { CardGroupsSpotlightResRow } from '../../components/Dashboard/CardGroupsSpotlight';
import { CardTrendsRes } from '../../components/Dashboard/CardTrends/types';
import { SlideSettingsType } from '../../constants';
import { getBaseUrl } from '../../hooks/useReportData/helpers';
import { SurveyFilterParamsUI } from '../../hooks/useReportData/helpers/surveyFilterHelpers';
import { get, post } from '../../utils/backend';
import { FiltersUI } from '../filters';

export interface CommonDataUI {
  categories: Dictionary<string>;
  dimensionsList: any[];
  questions: Dictionary<any>;
  standardDimensions: any;
  vcpDepartmentWeights: any;
  vcpFinancialTarget: any;
}

export interface FetcherArgs {
  filters: SurveyFilterParamsUI;
  categories: CommonDataUI['categories'];
  commonData: CommonDataUI;
  questions: CommonDataUI['questions'];
  dimensionsList: CommonDataUI['dimensionsList'];
  slideSettings: SlideSettingsType;
  allFilters: Partial<FiltersUI>;
  visibleSlides: string[];
}

export const fetchOveralScore = async ({
  filters,
}: FetcherArgs): Promise<{ overalScore: CardGaugeOHBRes }> => {
  const data = await post('/reporting/discussion_materials/overall_score', filters, {
    browserCache: true,
  });
  return { overalScore: data };
};

export const fetchEnps = async ({ filters }: FetcherArgs): Promise<{ enps: CardGaugeENPSRes }> => {
  const data = await post('/reporting/discussion_materials/enps_score', filters, {
    browserCache: true,
  });
  return { enps: data };
};

export const fetchParticipationScore = async ({
  filters,
}: FetcherArgs): Promise<{ participationScore: CardGaugeParticipationRes }> => {
  const data = await post('/reporting/discussion_materials/participation_score', filters, {
    browserCache: true,
  });
  return { participationScore: data };
};

export const fetchTrends = async (args: FetcherArgs): Promise<{ trends: CardTrendsRes[] }> => {
  const data = await get('/reporting/discussion_materials/trends', {
    browserCache: true,
  });
  return { trends: data };
};

export const fetchCategoryScore = async ({
  filters,
}: FetcherArgs): Promise<{ categoryScore: CardCategoryRowRes[] }> => {
  const data = await post('/reporting/discussion_materials/category_score', filters, {
    browserCache: true,
  });
  return { categoryScore: data };
};

export const fetchGroupScore = async ({
  filters,
}: FetcherArgs): Promise<{ groupScore: CardGroupsSpotlightResRow[] }> => {
  const data = await post('/reporting/discussion_materials/group_score', filters, {
    browserCache: true,
  });
  return { groupScore: data };
};

export interface KeyFindingsInsightsRes {
  keyTerm: string;
  takeaway: string;
  insight: string;
  statement: string;
  score?: number;
}

export interface KeyFindingsRes {
  scoreInsight: {
    overallScore: {
      score: number;
      quartileNumber: number;
      insight: string;
    };
    enpsScore: {
      score: number;
      quartileNumber: number;
      insight: string;
    };
    participation: {
      score: number;
      insight: string;
    };
  };
  improvementInsights: KeyFindingsInsightsRes[];
  strengthsInsights: KeyFindingsInsightsRes[];
  potentialOpportunities: {
    keyTakeaway: string;
    potentialOpportunity: string;
  }[];
}

export const fetchKeyFindings = async ({
  filters,
}: FetcherArgs): Promise<{ keyFindings: KeyFindingsRes }> => {
  const data = await post(`${getBaseUrl(filters)}/key_findings`, filters, {
    browserCache: true,
  });
  return { keyFindings: data };
};

// Helper type to extract resolved value of a Promise
type PromiseResolvedValue<T> = T extends Promise<infer R> ? R : never;

// Helper type to map the return types of each function
type GetPromiseReturnTypes<T extends readonly unknown[]> = {
  [K in keyof T]: T[K] extends (...args: any) => Promise<any>
    ? PromiseResolvedValue<ReturnType<T[K]>>
    : never;
};

// Apply the helper type to fetch functions and merge them into a single type
export type DMRawDataUI = Partial<
  GetPromiseReturnTypes<
    [
      typeof fetchOveralScore,
      typeof fetchEnps,
      typeof fetchParticipationScore,
      typeof fetchTrends,
      typeof fetchCategoryScore,
      typeof fetchGroupScore,
    ]
  >[number]
>;

export const dmSlideFunctions = {
  //   keyFindings: [fetchKeyFindings],
  overview: [
    fetchOveralScore,
    fetchEnps,
    fetchParticipationScore,
    fetchTrends,
    fetchCategoryScore,
    fetchGroupScore,
  ],
};
