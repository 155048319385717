import { cloneDeep as lodashCloneDeep, isNil } from 'lodash';

import { QUESTIONS_TYPES } from '../../../constants';
import { post } from '../../../utils/backend';
import { cloneDeep, toArray } from '../../../utils/helpers';

import { getBaseUrl, mapCategories, mapDeltas, mapQuestions } from './index';
import { getSurveyFilterParams } from './surveyFilterHelpers';

const prepareFixedAndNumValuesRequestBody = ({
  organizationId,
  portfolioId,
  surveyIdList,
  groupIdList,
  hierarchyList,
  surveyId,
  filter,
  filterValue,
}) => ({
  organizationId,
  portfolioId,
  surveyIdList,
  groupIdList,
  hierarchyList,
  surveyId,
  filter,
  filterValue,
});

function getFixedAndNumerical(
  filters,
  questions,
  categories,
  dimensionsList,
  settings,
  allFilters,
) {
  const baseUrl = getBaseUrl(allFilters);
  const body = { ...filters };
  body.filter = settings?.Benchmarks?.type;
  if (toArray(settings?.Benchmarks?.value).length) {
    body.filterValue = JSON.stringify(settings.Benchmarks.value);
  } else {
    body.filterValue = null;
  }
  const promices = [
    post(`${baseUrl}/fixed_and_numerical_values`, prepareFixedAndNumValuesRequestBody(body)),
  ];
  if (toArray(filters.compareSurveyIdList).length && toArray(filters.compareHierarchyList).length) {
    const compareFilters = {
      ...filters,
      surveyIdList: filters.compareSurveyIdList,
      hierarchyList: filters.compareHierarchyList,
      groupIdList: filters.compareGroupIdList,
    };
    promices.push(
      post(
        `${baseUrl}/fixed_and_numerical_values`,
        prepareFixedAndNumValuesRequestBody(compareFilters),
      ),
    );
  }
  return Promise.all(promices).then((resp) =>
    fixedAndNumericalResponseMapper(resp, { filters, questions, categories, settings, allFilters }),
  );
}

export async function fixedAndNumericalResponseMapper(
  response,
  { filters, questions, categories, settings, allFilters },
) {
  const resp = lodashCloneDeep(response);
  const fixedAndNumerical = {
    current: {
      categories: mapCategories(toArray(resp[0]?.categories), categories, allFilters.surveyType),
      questions: mapQuestions(
        toArray(resp[0]?.questions),
        questions,
        allFilters.surveyType,
        allFilters,
      ),
    },
  };
  fixedAndNumerical.current.categories = fixedAndNumerical.current.categories.filter(
    (c) =>
      fixedAndNumerical.current.questions.filter((q) =>
        filters.portfolioId ? true : q.categoryId === c.id,
      ).length,
  );
  if (resp[1]) {
    fixedAndNumerical.prior = {
      categories: mapCategories(toArray(resp[1]?.categories), categories, allFilters.surveyType),
      questions: mapQuestions(
        toArray(resp[1]?.questions),
        questions,
        allFilters.surveyType,
        allFilters,
      ),
    };
    mapDeltas(fixedAndNumerical.current.questions, fixedAndNumerical.prior.questions, allFilters);
    fixedAndNumerical.current.categories.forEach((category) => {
      const priorScore = fixedAndNumerical.prior.categories.find(
        (priorCategory) => priorCategory.id === category.id,
      )?.score;
      category.delta = !isNil(priorScore)
        ? Math.round((category.score - priorScore) * 10) / 10
        : undefined;
    });
  }
  fixedAndNumerical.current.questions.forEach((item) => {
    if (
      fixedAndNumerical.current.categories.findIndex(
        (i) => i.name === categories[item.categoryId],
      ) === -1
    ) {
      fixedAndNumerical.current.categories.push({
        id: item.categoryId,
        name: categories[item.categoryId],
      });
    }
  });
  fixedAndNumerical.current.questions.sort((a, b) => b.score - a.score);
  fixedAndNumerical.current.categories.sort((a, b) => b.score - a.score);
  let npsQuestion;
  const npsQuestions = cloneDeep(
    fixedAndNumerical.current.questions.filter((q) => q.typeId === QUESTIONS_TYPES.NPS),
  );
  if (npsQuestions.length > 1) {
    npsQuestion = npsQuestions.find((q) => q.keyTerm.toLowerCase().includes('enps'));
    if (!npsQuestion) {
      npsQuestion = npsQuestions.find((q) => q.text.toLowerCase().includes('enps'));
    }
    if (!npsQuestion) {
      [npsQuestion] = npsQuestions;
    }
  } else if (npsQuestions.length === 1) {
    [npsQuestion] = npsQuestions;
  }
  if (npsQuestion) {
    fixedAndNumerical.current.npsQuestion = npsQuestion;
    filters.questionId = npsQuestion.id;
    return post(
      `/reporting/discussion_materials/get_detractors_scores?enableAISummary=${settings?.enableAISummary}`,
      filters,
    ).then((res) => {
      fixedAndNumerical.current.npsQuestion.overallScoreByNps = res.overallScoreByNps;
      fixedAndNumerical.current.npsQuestion.statements = toArray(res.statements);
      fixedAndNumerical.current.npsQuestion.topics = res.topics ?? [];
      if (toArray(res.questions).length) {
        fixedAndNumerical.current.questions.forEach((curQuestion) => {
          const detractorScoreResQuestion = res.questions.find(
            (detractorScoreResponseItem) =>
              questions[detractorScoreResponseItem.id].keyTerm === curQuestion.keyTerm &&
              questions[detractorScoreResponseItem.id].categoryId === curQuestion.categoryId,
          );
          if (detractorScoreResQuestion) {
            curQuestion.detractorScore = detractorScoreResQuestion.score;
            curQuestion.detractorGap = detractorScoreResQuestion.score - curQuestion.score;
          }
        });
      }
      return { fixedAndNumerical: fixedAndNumerical.current };
    });
  }
  return { fixedAndNumerical: fixedAndNumerical.current };
}

export async function fixedAndNumericalBulkLoader(filters) {
  const slideName = 'fixedAndNumerical';
  const bulkDetails = filters.map(getSurveyFilterParams).map(prepareFixedAndNumValuesRequestBody);

  function checkReqDetailsInList(list, reqeustDetails) {
    return list.some(
      (item) =>
        item.surveyIds.length === reqeustDetails.surveyIdList.length &&
        item.surveyIds.every((id) => reqeustDetails.surveyIdList.includes(id)) &&
        item.groupIds.length === reqeustDetails.groupIdList.length &&
        item.groupIds.every((id) => reqeustDetails.groupIdList.includes(id)),
    );
  }

  const alreadyPreparedRequestIds = bulkDetails.reduce((uniqIds, viewDetail) => {
    if (checkReqDetailsInList(uniqIds, viewDetail)) {
      return uniqIds;
    }
    uniqIds.push({ surveyIds: viewDetail.surveyIdList, groupIds: viewDetail.groupIdList });
    return uniqIds;
  }, []);

  const comparedSurveysDetails = filters
    .filter(({ compareSurveyIdList }) => compareSurveyIdList && compareSurveyIdList.length)
    .filter(
      ({ compareSurveyIdList, compareGroupIdList }) =>
        !checkReqDetailsInList(alreadyPreparedRequestIds, {
          surveyIdList: compareSurveyIdList,
          groupIdList: compareGroupIdList,
        }),
    )
    .map(
      ({
        compareSurveyIdList,
        compareGroupIdList,
        compareFilterGroupIdList,
        orgId,
        portfolioId,
      }) => ({
        surveyIdList: compareSurveyIdList,
        hierarchyList: toArray(compareFilterGroupIdList).length
          ? compareFilterGroupIdList
          : compareGroupIdList,
        groupIdList: toArray(compareFilterGroupIdList).length
          ? compareFilterGroupIdList
          : compareGroupIdList,
        organizationId: orgId,
        portfolioId,
      }),
    );

  bulkDetails.push(...comparedSurveysDetails);

  const baseUrl = '/reporting/discussion_materials';
  const fixAndNumBulk = post(`${baseUrl}/fixed_and_numerical_values/bulk`, bulkDetails);

  return fixAndNumBulk.then((fixAndNumBulkRes) => {
    const fixAndNumBulkMap = fixAndNumBulkRes.reduce(
      (acc, loadDetail) => ({
        ...acc,
        ...loadDetail.surveyIds.reduce(
          (survAcc, surveyId) => ({
            ...survAcc,
            [surveyId]: loadDetail.groupIds.reduce(
              (grpAcc, grpId) => ({ ...grpAcc, [grpId]: loadDetail }),
              {},
            ),
          }),
          {},
        ),
      }),
      {},
    );
    return { slideName, [slideName]: fixAndNumBulkMap, mapper: fixedAndNumericalResponseMapper };
  });
}

export default getFixedAndNumerical;
