import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, notification } from 'antd';
import axios from 'axios';
import moment from 'moment';

import './echartsTheme';

import NoAccess from './pages/403';
import { UserContext } from './store/user';
import { showWarning } from './utils/alerts';
import ss from './utils/ss';
import { DEFAULT_API_FORMAT } from './constants';
import { initHotjar } from './initHotjar';
import Router from './Router';
import versionData from './version.json';

import './App.scss';

function App() {
  const [intervalTime, setIntervalTime] = useState(1000 * 60 * 10);
  const location = useLocation();
  const mounted = useRef<boolean>(false);
  const { logout, user } = useContext(UserContext);

  useEffect(() => {
    if (!user.email || !user.roleCode || !user.baseUserId) return;
    const hjIdentityData = {
      baseOrgName: user.baseOrgName,
      isEntromyEmail: user.email?.endsWith('@entromy.com'),
      role: user.roleCode,
    };
    const hj = (window as any).hj as any;
    try {
      hj('identify', user.baseUserId, hjIdentityData);
    } catch (err) {
      console.error('Hotjar identify error:', err);
    }
  }, [user?.email, user?.roleCode]);

  function reloadPage() {
    window.location.reload();
  }

  useEffect(() => {
    const previousUrl = ss.get<string>('previousUrl');
    const mustChangePassword = ss.get<boolean>('haveToChangePassword') ?? false;
    if (previousUrl === '/change-password' && mustChangePassword) {
      logout();
    }
    ss.set('previousUrl', location.pathname + location.search + location.hash);
  }, [location]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      window.Intercom('update', { last_request_at: Math.round(new Date().getTime() / 1000) });
    }
  }, [location]);

  useEffect(() => {
    // get('/questions/benchmark_refresh');
    // get('/cache/statements_refresh');
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    // eslint-disable-next-line no-useless-escape
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      showWarning(
        'Entromy has limited support for IE 11. Please consider upgrading your browser.',
        'Upgrade your browser',
      );
    }
    const userVersion = versionData.version;
    let { maintenanceDate } = versionData;
    // eslint-disable-next-line
    console.log(`user version: ${userVersion}`);

    function checkMaintenanceMode() {
      if (maintenanceDate && moment(maintenanceDate, DEFAULT_API_FORMAT).isValid()) {
        // eslint-disable-next-line
        console.log(`maintenance date: ${maintenanceDate}`);
        setIntervalTime(1000 * 60);
        if (
          moment().isBetween(moment(maintenanceDate).add(-15, 'minutes'), moment(maintenanceDate))
        ) {
          const timeLeft = moment(maintenanceDate).fromNow();
          notification.warning({
            description: `Entromy will be going down for maintenance ${timeLeft}`,
            duration: 0,
            key: 'maintenance-notification',
            message: 'Upcoming maintenance',
          });
        } else if (
          moment().isAfter(moment(maintenanceDate)) &&
          window.location.href.indexOf('maintenance') === -1
        ) {
          window.location.href = `${window.location.origin}/maintenance`;
        }
      } else {
        setIntervalTime(1000 * 60 * 10);
        if (window.location.href.indexOf('maintenance') !== -1) {
          window.location.href = window.location.origin;
        }
      }
    }

    function checkVersion() {
      axios
        .get(`${window.location.origin}/version.json?timestamp=${new Date().getTime()}`)
        .then((response) => {
          const latestVersion = response.data?.version;
          maintenanceDate = response.data?.maintenanceDate;
          // eslint-disable-next-line
          console.log(`latest version: ${latestVersion}`);
          checkMaintenanceMode();
          if (userVersion !== latestVersion) {
            notification.warning({
              description: (
                <>
                  <div>We have updated Entromy.</div>
                  <Button onClick={reloadPage} type="link" className="p-0">
                    Refresh now
                  </Button>{' '}
                  for a better experience.
                </>
              ),
              duration: 0,
              key: 'version-notification',
              message: 'Refresh to update the app',
            });
          }
        });
    }

    checkMaintenanceMode();
    const interval = setInterval(checkVersion, intervalTime);
    return () => clearInterval(interval);
  }, [intervalTime]);

  useEffect(() => {
    initHotjar();
  }, []);

  if (window.self !== window.top) {
    return <NoAccess />;
  }

  return <Router />;
}

export default App;
