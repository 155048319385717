import { useEffect, useMemo, useRef, useState } from 'react';
import { sanitize } from 'dompurify';
import { mapKeys, mapValues, uniq } from 'lodash';

import { REPORT_VIEW_TYPE } from '../../constants';
import { fetchStandardDimensions, standardDimensionsToMap } from '../../store/standardDimensions';
import { fetchUserDimensions } from '../../store/userDimensions';
import { clearRequestsProgress, get, post } from '../../utils/backend';
import { cloneDeep, toArray } from '../../utils/helpers';

import get360Special from './helpers/get360Special';
import get360SpecialManager from './helpers/get360SpecialManager';
import getFixedAndNumerical, { fixedAndNumericalBulkLoader } from './helpers/getFixedAndNumerical';
import getResultsByDimension from './helpers/getResultsByDimension';
import { getSurveyFilterParams } from './helpers/surveyFilterHelpers';
import { getBaseUrl } from './helpers';

const initialState = {
  questions: {},
  categories: {},
  overview: {},
};

let dimensions;
function getDimensions() {
  if (dimensions) {
    return { dimensionsList: dimensions };
  }
  return fetchUserDimensions().then((list) => {
    const dimensionsList = toArray(list);
    dimensions = dimensionsList;
    return { dimensionsList };
  });
}

const orgQuestions = {};
function getQuestions(orgId) {
  if (orgQuestions[orgId]) {
    return { questions: orgQuestions[orgId] };
  }
  return get(`/questions?orgID=${orgId}`, { browserCache: true }).then((list) => {
    const questions = {};
    if (toArray(list).length) {
      list.forEach((item) => {
        questions[item.id] = {
          id: item.id,
          categoryId: item.category?.id,
          text: sanitize(item.textval, { ALLOWED_TAGS: [] }),
          keyTerm: sanitize(item.stringval, { ALLOWED_TAGS: [] }),
          typeId: item.type?.id,
          questionMetadata: item.questionMetadata,
          translations: item.translations,
          themeId: item.questionTheme?.id,
          relatedQuestionId: item.relatedQuestionId,
        };
      });
      orgQuestions[orgId] = questions;
    }
    return { questions };
  });
}

const orgCategories = {};
function getCategories(orgId) {
  if (orgCategories[orgId]) {
    return { categories: orgCategories[orgId] };
  }
  return get(`/category?orgID=${orgId}`, { browserCache: true }).then((list) => {
    const categories = {};
    if (toArray(list).length) {
      list.forEach((item) => {
        categories[item.id] = item.textval;
      });
      orgCategories[orgId] = categories;
    }
    return { categories };
  });
}

export async function getActionPlan(filters) {
  return post('/reporting/action_plan/get', {
    ...filters,
  }).then((data) => ({ actionPlan: data }));
}

async function getOverview(filters, questions, categories, dimensionsList, settings, allFilters) {
  const body = {
    ...filters,
    filter: settings?.Benchmarks?.type,
    filterValue:
      settings?.Benchmarks?.value?.length > 0 ? JSON.stringify(settings.Benchmarks.value) : null,
  };
  const promises = [post(`${getBaseUrl(allFilters)}/overview`, body)];
  if (toArray(filters.compareSurveyIdList).length && toArray(filters.compareHierarchyList).length) {
    const compareFilters = {
      ...body,
      surveyIdList: filters.compareSurveyIdList,
      hierarchyList: filters.compareHierarchyList,
      groupIdList: filters.compareGroupIdList,
    };
    promises.push(post(`${getBaseUrl(allFilters)}/overview`, compareFilters));
  }
  const [currentRes, compareRes] = await Promise.all(promises);

  if (allFilters.surveyType === '360' && currentRes?.score) {
    currentRes.score = Math.round(currentRes.score / 2) / 10;
  }
  if (allFilters.surveyType === '360' && compareRes?.score) {
    compareRes.score = Math.round(compareRes.score / 2) / 10;
  }
  return {
    overview: { ...currentRes, orgName: currentRes.orgName || 'Your company' },
    prevOverview: compareRes,
  };
}

function getTopStatements(filters, questions, categories, dimensionsList, settings, allFilters) {
  return post(
    `/reporting/discussion_materials/key_topics?enableAISummary=${settings?.enableAISummary}`,
    {
      ...filters,
      limit: allFilters?.surveyType === '360' ? 500 : undefined,
    },
  ).then((data) => ({ topStatements: data }));
}

function get360Paricipants(filters) {
  return post('/instances/respondents_by_filter_values', filters).then((data) => ({
    participants360: data,
  }));
}

function getFixedBreakdownByDimmension(
  filters,
  questions,
  categories,
  dimensionsList,
  settings,
  allFilters,
) {
  if (allFilters.surveyType === '360') {
    return post('/reporting/discussion_materials/answer_options_breakdown_by_dimension', {
      ...filters,
      filter: 'relationship',
    }).then((data) => ({ fixedBreakdownByDimmension: data }));
  }
  return Promise.resolve();
}

function getFixedBreakdown(filters) {
  return post('/reporting/discussion_materials/answer_options_breakdown', filters).then((data) => ({
    fixedBreakdown: data,
  }));
}

function getMultiselectData(filters) {
  return post('/reporting/discussion_materials/multiselect_values', filters).then((data) => ({
    multiselectData: data,
  }));
}

function getAll360Fixed(filters, questions, categories, dimensionsList, settings) {
  if (!settings?.[360]?.showBenchmark) {
    return Promise.resolve();
  }
  return post('/reporting/discussion_materials/get_fixed_values_for_parent_360', filters).then(
    (data) => ({ all360Fixed: data }),
  );
}

function getFollowingQuestionsStatements(filters) {
  return post('/reporting/discussion_materials/statements_for_following_questions', filters).then(
    (data) => ({ followingStatements: data }),
  );
}

function getListSelects(filters) {
  return post('/reports/discussion_materials/get_list_selects', filters).then((data) => ({
    listselects: data,
  }));
}

function getFixedAndNumericalVCP(filters) {
  return post('/reporting/vcp/fixed_and_numerical_for_vcp_questions', filters).then((data) => ({
    vcpFixedAndNumerical: data,
  }));
}

function getFixedAndNumericalByDriver(filters) {
  return post('/reporting/vcp/fixed_and_numerical_by_driver', filters).then((data) => ({
    vcpQuestionsByDriver: data,
  }));
}

function getFixedAndNumericalVCPByDepartment(
  filters,
  questions,
  categories,
  dimensionsList,
  settings,
  allFilters,
  slides,
  commonData,
) {
  const dimension = dimensionsList.find(
    (i) =>
      i.id === commonData?.standardDimensions?.standardDimensionCodeToOrgDimensionId?.department,
  );
  const body = cloneDeep(filters);
  body.filter = dimension?.dimension_code;

  return post('/reporting/vcp/fixed_and_numerical_by_dimension_for_vcp_questions', body).then(
    (data) => ({ vcpFixedAndNumericalByDepartment: data }),
  );
}

function getFinancialTarget() {
  return get('/financial_target', { browserCache: true }).then((data) => ({
    vcpFinancialTarget: data,
  }));
}

function getValueDrivers() {
  return get('/financial_target_value_drivers').then((data) => ({ vcpDrivers: data }));
}

function getValueDriversRoles() {
  return get('/financial_target_talent_allocations').then((data) => ({ vcpDriverRoles: data }));
}

function getVCPDepartmentWeight() {
  return get('/reporting/vcp/departments_weight', { browserCache: true }).then((data) => ({
    vcpDepartmentWeights: data,
  }));
}

function getStandardDimensions() {
  return fetchStandardDimensions().then((data) => ({
    standardDimensions: {
      list: data,
      standardDimensionCodeToOrgDimensionCode: standardDimensionsToMap(
        data,
        'organizationDimensionCode',
      ),
      standardDimensionCodeToOrgDimensionId: standardDimensionsToMap(
        data,
        'organizationDimensionId',
      ),
    },
  }));
}

function getOrganizationalMaturity(filters) {
  return post('/reporting/vcp/organization_maturity', filters).then((data) => ({
    organizationMaturity: data,
  }));
}

function getCommentsByDriver(filters) {
  return post('/reporting/vcp/top_statements_by_driver_for_vcp_questions', filters).then(
    (data) => ({ vcpStatementsByDriver: data }),
  );
}

function getCommentsByDepartment(
  filters,
  questions,
  categories,
  dimensionsList,
  settings,
  allFilters,
  slides,
  commonData,
) {
  const dimension = dimensionsList.find(
    (i) =>
      i.id === commonData?.standardDimensions?.standardDimensionCodeToOrgDimensionId?.department,
  );
  const body = cloneDeep(filters);
  body.filter = dimension?.dimension_code;
  return post('/reporting/vcp/top_statements_by_dimension_for_vcp_questions', body).then(
    (data) => ({ vcpStatementsByDepartment: data }),
  );
}

function getVCPRolesUsers(filters) {
  return post('/reporting/vcp/members_by_critical_roles', filters).then((data) => ({
    vcpRolesUsers: data,
  }));
}

function getVCPComments(filters) {
  return post('/reporting/vcp/top_statements_for_vcp_questions', filters).then((data) => ({
    vcpComments: data,
  }));
}

async function getOnaCollaborationAndOutreachBetweenGroups(
  filters,
  questions,
  categories,
  dimensionsList,
  settings,
) {
  const dimensionCode = settings.ONA.dimensionCode;
  const data = await post('/reporting/discussion_materials/ona_collaboration', {
    ...filters,
    dimensionFilter: dimensionCode,
  });

  return {
    onaCollaborationAndOutreachBetweenGroups: {
      data,
      dimensionName: dimensionsList?.find((x) => x.dimension_code === dimensionCode)?.display_name,
    },
  };
}

async function getOnaPotentialInfluencersIdentified(
  filters,
  questions,
  categories,
  dimensionsList,
  settings,
) {
  const dimensionFieldToCode = mapKeys(
    settings.ONA.tableDimensions,
    (x, i) => `dimension${parseInt(i) + 1}`,
  );

  const dimensionCodeToName = (dimensionsList ?? []).reduce(
    (acc, item) => ({ ...acc, [item.dimension_code]: item.display_name }),
    {},
  );

  const dimensionFieldToName = mapValues(dimensionFieldToCode, (code) => dimensionCodeToName[code]);

  const data = await post('/reporting/discussion_materials/top_influencers', {
    ...filters,
    ...dimensionFieldToCode,
    showAll: true,
  });
  return {
    onaPotentialInfluencersIdentified: {
      dimensionFieldToName,
      data,
    },
  };
}

async function getOnaTopInfluencers(filters) {
  const data = await post('/reporting/discussion_materials/top_influencers_by_question', filters);
  return { onaTopInfluencers: data };
}

async function getOnaCollaborationBetweenTeamsByDimension(
  filters,
  questions,
  categories,
  dimensionsList,
  settings,
) {
  const dimensionCode = settings.ONA.dimensionCode;
  const data = await post('/reporting/discussion_materials/dimensions_connections', {
    ...filters,
    dimensionFilter: dimensionCode,
  });
  return {
    onaCollaborationBetweenTeamsByDimension: {
      dimensionName: dimensionsList?.find((x) => x.dimension_code === dimensionCode)?.display_name,
      data,
    },
  };
}

async function getOnaConnectionsBetweenInfluencersInEachGroupByDimension(
  filters,
  questions,
  categories,
  dimensionsList,
  settings,
) {
  const dimensionCode = settings.ONA.dimensionCode;
  const data = await post('/reporting/discussion_materials/top_influencers_connections', {
    ...filters,
    dimensionFilter: dimensionCode,
  });
  return {
    onaConnectionsBetweenInfluencersInEachGroupByDimension: {
      data,
      dimensionName: dimensionsList?.find((x) => x.dimension_code === dimensionCode)?.display_name,
    },
  };
}

function getVCPRoleScoresByManager(filters) {
  return post('/reporting/vcp/manager_scores_by_role', filters).then((data) => ({
    vcpRoleScoresByManager: data,
  }));
}

async function getVCPScoresByDriverAndRole(filters) {
  const data = await post('/reporting/vcp/scores_by_driver_and_role', filters);
  return { vcpScoresByDriverAndRole: data };
}

// TODO: PM-1902
// async function getBulkKeyFindings(filters) {
//   const data = await post(`${getBaseUrl(filters)}/key_findings`, filters);
//   return { keyFindings: data };
// }

export function getCommon(filters) {
  return Promise.all([
    getCategories(filters.orgId),
    getQuestions(filters.orgId),
    getDimensions(),
    getFinancialTarget(),
    getVCPDepartmentWeight(),
    getStandardDimensions(),
  ]);
}

export const slideFunctions = {
  actionPlan: [getActionPlan],
  overview: [getOverview, getFixedAndNumerical], // UI - migrated, PPT - not migrated
  businessOverview: [getOverview, getFixedAndNumerical],
  fixedByDimension: [getOverview, getFixedAndNumerical, getResultsByDimension],
  fixedByDimensionKeys: [getResultsByDimension],
  topStatements: [getTopStatements],
  fixedBreakdown: [getFixedAndNumerical, getFixedBreakdown, getFixedBreakdownByDimmension],
  fixedAndNumerical: [getOverview, getFixedAndNumerical],
  strengths: [getFixedAndNumerical],
  benchmarks: [getFixedAndNumerical],
  keyFindings: [getFixedAndNumerical, getTopStatements],
  // keyFindingsBulk: [getBulkKeyFindings], // TODO: PM-1902
  participation: [getOverview, getResultsByDimension],
  overallGraphs: [getResultsByDimension],
  statementsByDimension: [getResultsByDimension],
  listSelectsByDimension: [getResultsByDimension],
  nps: [getResultsByDimension],
  manager360: [getOverview, get360SpecialManager],
  special360: [getOverview, get360Special, get360SpecialManager, get360Paricipants],
  keyScores: [getFixedAndNumerical, getResultsByDimension],
  multiselect: [getMultiselectData],
  coreCompetencies: [
    getFixedAndNumerical,
    getMultiselectData,
    getFixedBreakdown,
    getFixedBreakdownByDimmension,
    getAll360Fixed,
  ],
  supportData: [getFollowingQuestionsStatements],
  listselect: [getListSelects],
  vcpBusinessOverview: [
    getOverview,
    getFixedAndNumerical,
    getFixedAndNumericalVCP,
    getFixedAndNumericalVCPByDepartment,
    getCommentsByDriver,
    getCommentsByDepartment,
    getVCPRolesUsers,
    getVCPRoleScoresByManager,
    getVCPScoresByDriverAndRole,
  ],
  vcpOverallScores: [getFixedAndNumericalByDriver],
  organizationalMaturity: [getOrganizationalMaturity],
  vcpBridge: [getValueDrivers],
  keyRoles: [getValueDriversRoles, getValueDrivers],
  vcpComments: [getVCPComments],
  onaCollaborationAndOutreachBetweenGroups: [getOnaCollaborationAndOutreachBetweenGroups],
  onaPotentialInfluencersIdentified: [getOnaPotentialInfluencersIdentified],
  onaTopInfluencers: [getOnaTopInfluencers],
  onaCollaborationBetweenTeamsByDimension: [getOnaCollaborationBetweenTeamsByDimension],
  onaConnectionsBetweenInfluencersInEachGroupByDimension: [
    getOnaConnectionsBetweenInfluencersInEachGroupByDimension,
  ],
  // For force usage of the bulk set up settings.bulkAccepted
  bulkSlideImplementations: {
    [getFixedAndNumerical.name]: { loader: fixedAndNumericalBulkLoader, vcpLoader: null },
  },
};

export function getLoadSingleRawDataPromises(
  slides,
  filters,
  questions,
  categories,
  dimensionsList,
  settings,
  commonData,
) {
  const fullFunctionsSet = getRawDataLoaders(
    slides,
    filters.reportType === REPORT_VIEW_TYPE.Vcp,
    settings?.bulkAccepted,
  );
  const functionsSet = fullFunctionsSet?.singleLoadFunctions;
  const body = getSurveyFilterParams(filters);

  const promices = [];
  functionsSet.forEach((functionItem) => {
    promices.push(
      functionItem(
        { ...body },
        questions,
        categories,
        dimensionsList,
        filters.reportType === 'vcp' ? { ...settings, enableAISummary: false } : settings,
        filters,
        slides,
        commonData,
      ),
    );
  });
  return promices;
}

function useCommons(singleFilter) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    if (!singleFilter.orgId) {
      return;
    }

    const load = async () => {
      setLoading(true);
      try {
        const res = await getCommon(singleFilter);
        setData(Object.assign({}, ...res));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setFailed(true);
      }
    };

    load();
  }, [singleFilter.orgId]);

  return {
    data,
    loading,
    failed,
  };
}

function useRawReportData(
  slides,
  singleFilter,
  settings,
  slideSettings,
  useDmLoaded,
  loadingQueueNum,
) {
  const [data, setData] = useState(initialState);
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const loadingQueueNumRef = useRef(loadingQueueNum);
  loadingQueueNumRef.current = loadingQueueNum;
  const {
    loading: commonLoading,
    data: commonData,
    failed: commonFailed,
  } = useCommons(singleFilter);

  const load = async () => {
    const loadingQueueNumInClosure = loadingQueueNumRef.current;
    if (singleFilter.noAccess) {
      setLoading(false);
    } else {
      setLoading(true);

      const bulkDataLoaders = getRawDataLoaders(
        slides,
        singleFilter.reportType === REPORT_VIEW_TYPE.Vcp,
        settings?.bulkAccepted,
      )?.bulkLoadFunctions;
      const loadBulkRawDataPromise = loadBulkRawData(bulkDataLoaders, [singleFilter]);

      try {
        clearRequestsProgress();

        const res = await Promise.all(
          getLoadSingleRawDataPromises(
            slides,
            singleFilter,
            commonData.questions,
            commonData.categories,
            commonData.dimensionsList,
            slideSettings,
            commonData,
          ).concat(loadBulkRawDataPromise),
        );

        if (loadingQueueNumInClosure !== loadingQueueNumRef.current) {
          return;
        }

        const bulkResponses = settings?.bulkAccepted ? res.pop() : [];
        const bulkValues = await mapBulkValues(bulkResponses, singleFilter, {
          questions: commonData.questions,
          categories: commonData.categories,
        });
        if (loadingQueueNumInClosure !== loadingQueueNumRef.current) {
          return;
        }
        setData(() => Object.assign({}, commonData, ...res, bulkValues));
        setLoading(false);
      } catch (e) {
        if (loadingQueueNumInClosure !== loadingQueueNumRef.current) {
          return;
        }
        console.error('useRawReportData error', e);
        setFailed(true);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (
      commonLoading ||
      commonFailed ||
      !useDmLoaded ||
      singleFilter.loading ||
      !toArray(singleFilter?.hierarchyList).length ||
      !toArray(singleFilter?.surveyIdList).length
    ) {
      return;
    }

    load();
  }, [commonLoading, commonFailed, useDmLoaded, loadingQueueNum, singleFilter.surveyName]);

  const allLoading = loading || commonLoading || !useDmLoaded;
  const allFailed = failed || commonFailed;

  return useMemo(
    () => ({ data, loading: allLoading, failed: allFailed }),
    [data, allLoading, allFailed],
  );
}

async function mapBulkValues(bulkResponses, singleFilter, { questions, categories }) {
  if (!bulkResponses?.length) return {};
  const surveyFilter = getSurveyFilterParams(singleFilter);
  return getMappedBulkValues(
    bulkResponses,
    singleFilter,
    {
      questions,
      categories,
    },
    (bulkResponse) => {
      const comparedItems = [
        bulkResponse[bulkResponse.slideName][surveyFilter.surveyIdList[0]]?.[
          surveyFilter.groupIdList[0]
        ],
      ];
      if (surveyFilter.compareSurveyIdList?.length) {
        comparedItems.push(
          bulkResponse[bulkResponse.slideName][surveyFilter.compareSurveyIdList[0]]?.[
            surveyFilter.compareGroupIdList[0]
          ],
        );
      }
      return comparedItems;
    },
  );
}

export function getRawDataLoaders(requestedSlides, isVcp, acceptBulkLoaders) {
  const requestedSlideFunctionNames = uniq(
    requestedSlides.reduce(
      (functions, slide) => functions.concat(...(slideFunctions[slide] ?? []).map((fn) => fn.name)),
      [],
    ),
  );
  const slideBulkImplementations = new Set();
  if (acceptBulkLoaders) {
    requestedSlideFunctionNames
      .filter((slideName) => checkBulkImplementations(slideName, isVcp))
      .forEach((fn) => slideBulkImplementations.add(fn));
  }

  const singleLoadFunctions = new Set();
  requestedSlides
    .reduce(
      (functions, slide) =>
        slideFunctions[slide] ? functions.concat(...slideFunctions[slide]) : functions,
      [],
    )
    .filter((fn) => !slideBulkImplementations.has(fn.name))
    .forEach((fn) => singleLoadFunctions.add(fn));

  return {
    singleLoadFunctions: [...singleLoadFunctions],
    bulkLoadFunctions: [...slideBulkImplementations],
  };
}

export function checkBulkImplementations(slideName, isVcp) {
  return isVcp
    ? slideFunctions.bulkSlideImplementations[slideName]?.vcpLoader
    : slideFunctions.bulkSlideImplementations[slideName]?.loader;
}

export async function loadBulkRawData(slideBulkImplementations, filters) {
  const loadPromises = slideBulkImplementations.map((slideName) =>
    loadSingleSlidesBulkData(slideName, filters),
  );
  return Promise.all(loadPromises);
}

async function loadSingleSlidesBulkData(slideName, filters) {
  return checkBulkImplementations(slideName)(filters);
}

export async function getMappedBulkValues(
  bulkResponses,
  filter,
  { questions, categories },
  itemsResolutionFn = (resp) => resp,
) {
  const promises = bulkResponses.map((bulkResponse) => {
    const comparedItems = itemsResolutionFn(bulkResponse);
    return bulkResponse.mapper(comparedItems, {
      filters: {
        portfolioId: filter.portfolioId,
        organizationId: filter.organizationId ?? filter.orgId,
        surveyIdList: filter.surveyIdList,
        hierarchyList: filter.hierarchyList,
        groupIdList: filter.groupIdList?.length ? filter.groupIdList : filter.hierarchyList,
      },
      questions,
      categories,
      allFilters: filter,
    });
  });
  return Promise.all(promises).then((mappedResponses) => {
    return mappedResponses.reduce((obj, resp) => ({ ...obj, ...resp }), {});
  });
}

export default useRawReportData;
