/* eslint-disable no-template-curly-in-string */
export const validateMessages = {
  number: {
    range: 'Must be between ${min} and ${max}',
  },
  required: '${label} is required',
  types: {
    email: 'Email is not valid!',
    number: 'Not a valid number',
  },
};

export const FIELD_IS_REQUIRED = 'Field is required';

export const ALLOWED_TAGS = [
  'a',
  'b',
  'blockquote',
  'br',
  'caption',
  'cite',
  'code',
  'col',
  'colgroup',
  'dd',
  'div',
  'dl',
  'dt',
  'em',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'i',
  'img',
  'li',
  'ol',
  'p',
  'pre',
  'q',
  'small',
  'span',
  'strike',
  'strong',
  'sub',
  'sup',
  'table',
  'tbody',
  'td',
  'tfoot',
  'th',
  'thead',
  'tr',
  'u',
  'ul',
  'big',
];

export const ALLOWED_ATTRIBUTES = [
  'href',
  'title',
  'cite',
  'span',
  'width',
  'align',
  'alt',
  'height',
  'src',
  'start',
  'type',
  'summary',
  'abbr',
  'axis',
  'colspan',
  'rowspan',
  'scope',
  'ftp',
  'http',
  'https',
  'mailto',
  'style',
];
