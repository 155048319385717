import React, { useEffect, useState } from 'react';
import { faEdit, faSave, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Button, Col, Input, message, Modal, Row, Select, Table, Tooltip } from 'antd';
import { sanitize } from 'dompurify';
import PropTypes from 'prop-types';

import { showError } from '../../../utils/alerts';
import { get, post, put, remove } from '../../../utils/backend';
import { cloneDeep, toArray } from '../../../utils/helpers';
import Loader from '../../Loader';

import StatementsCreator from './StatementsCreator';

import './styles.scss';

const { Option } = Select;
const { TextArea } = Input;

const SeedQuestions = React.memo(
  ({ visible, onCancel, surveyName, instanceId, surveyId, statementGroupDimensionId }) => {
    const [questions, setQuestions] = useState([]);
    const [questionList, setQuestionList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dimensionValues, setDimensionValues] = useState([]);

    function onValueSelect(id, row) {
      setQuestions((prevValue) => {
        const questionsCopy = cloneDeep(prevValue);
        const question = questionsCopy.find((item) => item.id === row.questionId);
        const statement = question.statements.find((item) => item.id === row.id);
        statement.dimensionValues.push(dimensionValues.find((item) => item.id === id));
        statement.loading = false;
        return questionsCopy;
      });
    }

    function mapStatements(questionsList, statements, valuesList) {
      return toArray(questionsList).map((item) => ({
        id: item.id,
        text: item.text,
        statements: toArray(statements)
          .filter((statement) => statement.questionId === item.id)
          .map((statement) => {
            let values = toArray(JSON.parse(statement.dimensionValues)).map((val) => {
              const value = valuesList.find((i) => i.id === val);
              if (!value) {
                return null;
              }
              return {
                id: value.id,
                name: value.name,
              };
            });
            values = values.filter((i) => i);
            return {
              id: statement.statementId,
              text: statement.statementTextval,
              savedText: statement.statementTextval,
              dimensionValues: values.sort((a, b) => a.name.localeCompare(b.name)),
              savedDimensionValues: values,
              questionId: item.id,
            };
          }),
      }));
    }

    function getStatements() {
      setLoading(true);
      get(`/statement/${instanceId}/seeded_statements`)
        .then((list) => {
          setQuestions(mapStatements(questionList, list, dimensionValues));
        })
        .finally(() => setLoading(false));
    }

    function onValueDeselect(id, row) {
      setQuestions((prevValue) => {
        const questionsCopy = cloneDeep(prevValue);
        const question = questionsCopy.find((item) => item.id === row.questionId);
        const statement = question.statements.find((item) => item.id === row.id);
        statement.dimensionValues = statement.dimensionValues.filter((item) => item.id !== id);
        statement.saving = false;
        return questionsCopy;
      });
    }

    function updateStatement(row, text, valuesList) {
      const body = {
        id: row.id,
        '@type': 'Statement',
        instance: { id: instanceId },
        question: { id: row.questionId },
        textval: text,
        dimensionValues: JSON.stringify(valuesList.map((item) => item.id)),
      };
      put('/answers/update_seed_statement', body)
        .then(() => {
          if (!visible) {
            return;
          }
          setQuestions((prevValue) => {
            const questionsCopy = cloneDeep(prevValue);
            const question = questionsCopy.find((item) => item.id === row.questionId);
            const statement = question.statements.find((item) => item.id === row.id);
            statement.dimensionValues = valuesList.sort((a, b) => a.name.localeCompare(b.name));
            statement.text = text;
            statement.savedDimensionValues = valuesList;
            statement.savedText = text;
            statement.saving = false;
            statement.editable = false;
            return questionsCopy;
          });
        })
        .catch((e) => {
          if (!visible) {
            return;
          }
          showError(e.message);
          setQuestions((prevValue) => {
            const questionsCopy = cloneDeep(prevValue);
            const question = questionsCopy.find((item) => item.id === row.questionId);
            const statement = question.statements.find((item) => item.id === row.id);
            statement.saving = false;
            statement.editable = true;
            return questionsCopy;
          });
        });
    }

    function saveStatement(row) {
      if (!row.dimensionValues.length) {
        message.error('At least one dimension value must be assigned');
      }
      if (!row.text.length) {
        message.error('Statement text should not be empty');
      }
      if (!row.dimensionValues.length || !row.text.length) {
        return;
      }
      setQuestions((prevValue) => {
        const questionsCopy = cloneDeep(prevValue);
        const question = questionsCopy.find((item) => item.id === row.questionId);
        const statement = question.statements.find((item) => item.id === row.id);
        statement.saving = true;
        statement.editable = false;
        statement.saving = true;
        return questionsCopy;
      });
      updateStatement(row, row.text, row.dimensionValues);
    }

    function setStatementText(row, text) {
      setQuestions((prevValue) => {
        const questionsCopy = cloneDeep(prevValue);
        const question = questionsCopy.find((item) => item.id === row.questionId);
        const statement = question.statements.find((item) => item.id === row.id);
        statement.text = text;
        return questionsCopy;
      });
    }

    function setStatementEditable(statementId, questionId, editable) {
      setQuestions((prevValue) => {
        const questionsCopy = cloneDeep(prevValue);
        const question = questionsCopy.find((item) => item.id === questionId);
        const statement = question.statements.find((item) => item.id === statementId);
        statement.dimensionValues = cloneDeep(statement.savedDimensionValues);
        statement.text = statement.savedText;
        statement.editable = editable;
        statement.saving = false;
        return questionsCopy;
      });
    }

    function removeStatement(statementId, questionId) {
      Modal.confirm({
        title: 'Are you sure?',
        content: 'You will not be able to recover this statement.',
        onOk() {
          const body = {
            id: statementId,
            '@type': 'Statement',
            instance: { id: instanceId },
            question: { id: questionId },
            textval: '',
            dimensionValues: JSON.stringify([]),
          };
          return remove('/answers/delete_seed_statement', body).then(() => {
            if (!visible) return;
            const questionsCopy = cloneDeep(questions);
            const question = questionsCopy.find((item) => item.id === questionId);
            question.statements = question.statements.filter((item) => item.id !== statementId);
            setQuestions(questionsCopy);
          });
        },
      });
    }

    useEffect(() => {
      if (!instanceId || !visible || !surveyId) {
        return;
      }
      const promices = [];
      const defaultItem = {
        id: '***_ALL_***',
        name: 'All',
      };
      let valuesList = [];
      promices.push(get(`/instances/${instanceId}/seed_questions`));
      promices.push(get(`/statement/${instanceId}/seeded_statements`));
      if (statementGroupDimensionId) {
        promices.push(
          post(`/dimension_metadata/${statementGroupDimensionId}/dimensions_values`, []).then(
            (resp) => {
              if (Array.isArray(resp)) {
                valuesList = [
                  defaultItem,
                  ...resp.map((item) => ({ id: item.value, name: item.value })),
                ];
              }
            },
          ),
        );
      } else {
        valuesList = [defaultItem];
      }
      Promise.all(promices)
        .then((data) => {
          const questionsList = toArray(data[0]).map((item) => ({
            id: item.id,
            text: item.textval,
          }));
          setDimensionValues(valuesList);
          setQuestions(mapStatements(questionsList, data[1], valuesList));
          setQuestionList(questionsList);
        })
        .catch((e) => showError(e.message))
        .finally(() => setLoading(false));
    }, [instanceId, visible, statementGroupDimensionId]);

    useEffect(() => {
      if (!visible) {
        setQuestions([]);
        setQuestionList([]);
        setDimensionValues([]);
        setLoading(true);
      }
    }, [visible]);

    const columns = [
      {
        title: 'Statement',
        dataIndex: 'text',
        key: 'text',
        width: '45%',
        sorter: (a, b) => a.text.trim().localeCompare(b.text.trim()),
        render: (text, row) =>
          row.editable ? (
            <TextArea
              autoSize
              value={text}
              onChange={(e) => setStatementText(row, e.target.value)}
            />
          ) : (
            text
          ),
      },
    ];
    if (statementGroupDimensionId) {
      columns.push({
        title: 'Dimension values',
        dataIndex: 'dimensionValues',
        key: 'dimensionValues',
        width: '40%',
        filters: dimensionValues.map((item) => ({
          text: item.name,
          value: item.id,
        })),
        onFilter: (value, row) => row.dimensionValues.findIndex((item) => item.id === value) !== -1,
        render: (value, row) =>
          row.saving || !row.editable ? (
            value.map((item) => item.name).join(', ')
          ) : (
            <Select
              className="w-100"
              value={value.map((item) => item.id)}
              mode="multiple"
              onSelect={(val) => onValueSelect(val, row)}
              onDeselect={(val) => onValueDeselect(val, row)}
              placeholder="Select dimension values"
              maxTagTextLength={20}
              optionFilterProp="children"
            >
              {dimensionValues.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          ),
      });
    }
    columns.push({
      title: 'Actions',
      dataIndex: 'id',
      key: 'actions',
      align: 'center',
      width: '15%',
      render: (id, row) => (
        <Row gutter={[4, 0]} justify="center">
          {row.editable && (
            <>
              <Col>
                <Tooltip title="Save">
                  <Button
                    icon={<Icon icon={faSave} />}
                    size="small"
                    onClick={() => saveStatement(row)}
                  />
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title="Cancel">
                  <Button
                    icon={<Icon icon={faTimes} />}
                    size="small"
                    onClick={() => setStatementEditable(id, row.questionId, false)}
                  />
                </Tooltip>
              </Col>
            </>
          )}
          {!row.editable && (
            <Col>
              <Tooltip title="Edit">
                <Button
                  icon={<Icon icon={faEdit} />}
                  loading={row.saving}
                  size="small"
                  onClick={() => setStatementEditable(id, row.questionId, true)}
                />
              </Tooltip>
            </Col>
          )}
          <Col>
            <Tooltip title="Delete">
              <Button
                icon={<Icon icon={faTrash} />}
                size="small"
                onClick={() => removeStatement(id, row.questionId)}
              />
            </Tooltip>
          </Col>
        </Row>
      ),
    });

    const hasData = questions.length && dimensionValues.length;

    return (
      <Modal
        title={`Seed statements for ${surveyName}`}
        open={visible}
        onCancel={onCancel}
        footer={null}
        width={900}
        className="seed-modal"
      >
        {loading && <Loader />}
        <p>
          <b>Seeded statements</b> are ideas that serve as a starting point for free response
          questions. When you seed a question, users may be asked to indicate their agreement with
          the seeded statements. This behavior will continue until enough users have provided their
          own responses to the question.
        </p>
        {!loading && !questions.length && (
          <p>
            Your survey does not have any <b>free-form response questions</b> associated with it.
            Please add some questions to your survey and return to this screen to seed them.
          </p>
        )}
        {!!hasData && (
          <>
            <p>
              Below, select a <b>question</b> and <b>dimension value(s)</b> that apply to your
              seeded statement(s). You can add <b>multiple statements</b> at once by separating each
              statement with a carriage return.
            </p>
            <StatementsCreator
              questions={questionList}
              dimensionValues={dimensionValues}
              instanceId={instanceId}
              onSave={getStatements}
            />
            {questions
              .filter((item) => item.statements.length)
              .map((item) => (
                <div key={item.id}>
                  <div
                    className="seed-modal__question"
                    dangerouslySetInnerHTML={{ __html: sanitize(item.text) }}
                  />
                  <Table
                    size="small"
                    rowKey="id"
                    dataSource={item.statements}
                    columns={columns}
                    pagination={false}
                  />
                </div>
              ))}
          </>
        )}
      </Modal>
    );
  },
);

SeedQuestions.defaultProps = {
  visible: false,
  onCancel: () => {},
  surveyName: '',
  instanceId: undefined,
  surveyId: undefined,
  statementGroupDimensionId: 0,
};

SeedQuestions.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  surveyName: PropTypes.string,
  instanceId: PropTypes.number,
  surveyId: PropTypes.number,
  statementGroupDimensionId: PropTypes.number,
};

export default SeedQuestions;
