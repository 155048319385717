import { memo } from 'react';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Avatar, theme } from 'antd';
import classNames from 'classnames';

import './styles.scss';

const { useToken } = theme;

interface UserAvatarProps {
  name?: string | null;
  className?: string;
  size?: number;
}

const UserAvatar = memo(({ name, className, size }: UserAvatarProps) => {
  const { token } = useToken();

  return (
    <Avatar
      size={size}
      style={{ alignContent: 'center', backgroundColor: token.colorPrimary }}
      className={classNames('user-avatar', className)}
    >
      {name ? (
        name
          .split(' ', 2)
          .map((word) => word.slice(0, 1))
          .join('')
      ) : (
        <Icon icon={faUser} />
      )}
    </Avatar>
  );
});

export default UserAvatar;
