import { post } from '../../../utils/backend';
import { toArray } from '../../../utils/helpers';

function get360Special(filters) {
  const promices = [post('/reporting/discussion_materials/360_special_values', filters)];
  if (toArray(filters.compareSurveyIdList).length && toArray(filters.compareHierarchyList).length) {
    const compareFilters = {
      ...filters,
      surveyIdList: filters.compareSurveyIdList,
      hierarchyList: filters.compareHierarchyList,
      groupIdList: filters.compareGroupIdList,
    };
    promices.push(post('/reporting/discussion_materials/360_special_values', compareFilters));
  }
  return Promise.all(promices).then((resp) => {
    const special360Data = { currentSpecial360: resp[0] };
    if (resp[1]) {
      special360Data.prevSpecial360 = resp[1];
    }
    return special360Data;
  });
}

export default get360Special;
