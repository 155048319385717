import { LRUCache } from 'lru-cache';

export const cache = new LRUCache<string, Promise<any>>({
  max: 500,
  ttl: 60 * 1000,
});

export const generateCacheKey = (method: string, url: string, data: any): string => {
  const dataKey = data ? JSON.stringify(data) : '';
  return `${method}:${url}:${dataKey}`;
};
