import { post } from '../../../utils/backend';
import { toArray } from '../../../utils/helpers';

import { cleanStatementData } from './cleanStatementData';
import { getBaseUrl } from '.';

async function getStatementsByDimension(
  filters,
  questions,
  settings,
  allFilters,
  dimensionsData,
  dimensionList,
) {
  const promices = [];
  const statementsDimensions = [];
  const topinfluencersCode = 'topinfluencers';
  const baseUrl = getBaseUrl(allFilters);
  dimensionList
    .filter((dimension) => dimension.visibleAppendix)
    .forEach((dimension) => {
      const dimensionData = dimensionsData.find((d) => d.id === dimension.dimension_code);
      if (dimensionData) {
        const promise = new Promise((resolve) => {
          const dimensionValues = dimensionData.dimensions.map((i) => i.name).slice(0, 50);
          let consolidatedDimensionValueList = {};
          const subPromices = [
            post(
              dimension.dimension_code === topinfluencersCode
                ? `${baseUrl}/statements_by_top_influencers`
                : `${baseUrl}/statements_by_dimensions?enableAISummary=${settings?.enableAISummary}`,
              {
                ...filters,
                dimensionValues,
                filter: dimension.dimension_code,
                limit: allFilters?.surveyType === '360' ? 500 : undefined,
              },
            ),
          ];
          if (dimension.consolidateCols && toArray(dimensionData.bucketDimensionValues).length) {
            consolidatedDimensionValueList = {
              q1: dimensionData.bucketDimensionValues[0],
              q2: dimensionData.bucketDimensionValues[1],
              q3: dimensionData.bucketDimensionValues[2],
              q4: dimensionData.bucketDimensionValues[3],
            };
            subPromices.push(
              post('/reporting/discussion_materials/statements_by_consolidated_dimensions', {
                ...filters,
                filter: dimension.dimension_code,
                consolidatedDimensionValueList,
              }),
            );
          }
          Promise.all(subPromices).then((data) => {
            if (toArray(data[0]?.categoryQuestions).length) {
              let questionsCleanedConsolidated = [];
              const questionsCleaned = cleanStatementData(
                data[0].categoryQuestions,
                allFilters,
                settings,
                questions,
              );
              if (toArray(data[1]?.categoryQuestions).length) {
                questionsCleanedConsolidated = cleanStatementData(
                  data[1].categoryQuestions,
                  allFilters,
                  settings,
                  questions,
                );
              }
              if (toArray(questionsCleaned).length) {
                statementsDimensions.push({
                  categoryQuestions: questionsCleaned,
                  questionsCleanedConsolidated,
                  dimensionValues,
                  id: dimension.dimension_code,
                  dimensionId: dimension.id,
                  name: dimension.display_name,
                });
              }
            }
            resolve();
          });
        });
        promices.push(promise);
      }
    });
  await Promise.all(promices);
  return { statementsDimensions };
}

export default getStatementsByDimension;
