import React, { useContext, useEffect } from 'react';
import { Checkbox, Form, Input, Modal } from 'antd';
import PropTypes from 'prop-types';

import { UserContext } from '../../../store/user';
import { FIELD_IS_REQUIRED } from '../../../utils/validation';
import TextEditor from '../../TextEditor';

const { TextArea } = Input;

const BODY_TEXT = `<p>Hello</p>

<p>Our survey is now live! We&rsquo;ve granted you access to the platform, and you should have received login credentials. You can access participation details under the manage button at any time and <a href="https://help.entromy.com/en/articles/4705475-how-can-i-pull-additional-details-on-participation-rates">pull additional information</a> under the user icon in XLS format.   &nbsp;</p>

<p>We&rsquo;ll plan to send reminders from the platform every couple of days to those who have not yet responded to the survey. You can also expect an update on the status of the survey results periodically throughout the window. Let us know if you need anything else. I&rsquo;m looping in our <a href="mailto:support@entromy.com">Entromy Customer Support</a> team to help with additional requests.  &nbsp;</p>

<p>Thanks,&nbsp;</p>

<p>Entromy Client Support&nbsp;</p>

<p><a href="https://help.entromy.com/en/">Help Desk</a>&nbsp;</p>

<ul>
<li><a href="https://help.entromy.com/en/articles/4705649-how-do-i-download-discussion-materials">How to download reports</a>&nbsp;</li>
<li><a href="https://help.entromy.com/en/articles/5554616-entromy-platform-overview">Entromy Platform Overview</a>&nbsp;</li>
<li><a href="https://help.entromy.com/en/articles/4705636-how-are-entromy-scores-calculated">How are Entromy scores calculated?</a>&nbsp;</li>
<li><a href="https://help.entromy.com/en/articles/4705616-how-do-open-ended-comments-work">How do open-ended comments work?</a>&nbsp;</li>
</ul>`;

const LaunchAndSend = React.memo(({ visible, onCancel, onSave }) => {
  const [form] = Form.useForm();
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!visible) return;
    form.setFieldsValue({
      subject: 'Survey is LIVE!',
      body: BODY_TEXT,
      sendMe: true,
    });
  }, [visible, form]);

  function onSubmit() {
    form.validateFields().then((values) => {
      const list = values.emails.split(',');
      if (values.sendMe) {
        list.push(user.email);
      }
      const subject = values.subject.trim();
      const body = values.body;
      onSave(list, subject, body);
      onCancel();
    });
  }

  return (
    <Modal
      width={920}
      title="Launch and Send"
      open={visible}
      onOk={onSubmit}
      onCancel={onCancel}
      okText="Launch and Send"
      afterClose={() => form.resetFields()}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="emails"
          label="Enter a comma-separated list of email addresses"
          rules={[{ required: true, message: FIELD_IS_REQUIRED }]}
        >
          <TextArea autoSize />
        </Form.Item>
        <Form.Item
          name="subject"
          label="Subject"
          rules={[{ required: true, message: FIELD_IS_REQUIRED }]}
        >
          <Input placeholder="Subject" />
        </Form.Item>
        <Form.Item
          label="Body"
          name="body"
          rules={[{ required: true, message: FIELD_IS_REQUIRED }]}
        >
          <TextEditor initialValue={BODY_TEXT} />
        </Form.Item>
        <Form.Item name="sendMe" valuePropName="checked">
          <Checkbox>Send myself a copy</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
});

LaunchAndSend.defaultProps = {
  visible: false,
  onSave: PropTypes.func,
  onCancel: () => {},
};

LaunchAndSend.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default LaunchAndSend;
