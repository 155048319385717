/**
 * Important! This is "lazy" context provider. It means that it will not fetch data on mount.
 * Correct: const hris = useGris();
 * Wrong: const hris = useProvider(HrisContext);
 */
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { message } from 'antd';

import { showError } from '../../utils/alerts';
import { get, patch, post, put, remove } from '../../utils/backend';

export interface HrisIntegrationRes {
  id: number;
  provider: string; // "Default",
  enabled: boolean;
  createdAt: string; // "2024-07-15",
  updatedAt: string | null; // '2024-07-15';
  lastSync: string | null; // null;
  organizationId: number; // 107;
  createdBy: number;
  error: any | null;
  isCompleted: boolean;
}

export interface HrisIntegrationUI {
  id: number;
  provider: string;
  enabled: boolean;
  createdAt: Date;
  updatedAt: Date | null;
  lastSync: Date | null;
  organizationId: number;
  createdBy: number;
  error: any | null;
  isCompleted: boolean;
}

export const hrisIntegrationResToUI = (res: HrisIntegrationRes): HrisIntegrationUI => ({
  ...res,
  createdAt: new Date(res.createdAt),
  lastSync: res.lastSync ? new Date(res.lastSync) : null,
  updatedAt: res.updatedAt ? new Date(res.updatedAt) : null,
});

interface LinkToken {
  id: number;
  token: string;
}

interface HrisContextType {
  cleanLinkToken: () => Promise<void>;
  createIntegration: (id: number, token: string) => Promise<void>;
  createLinkToken: (id: number | null) => Promise<void>;
  disableIntegration: (id: number) => Promise<void>;
  enableIntegration: (id: number) => Promise<void>;
  dataSync: () => Promise<void>;
  integrations: HrisIntegrationUI[];
  triggerRefetch: () => void;
  refetchToken: number;
  removeIntegration: (id: number, doRefetch?: boolean) => Promise<void>;
  setRefetchToken: Dispatch<SetStateAction<number>>;
  linkToken: LinkToken | undefined;
  loading: boolean;
  hasEnabledIntegration: boolean;
}

const HrisContext = createContext<HrisContextType | undefined>(undefined);

interface HrisProviderProps {
  children: React.ReactNode;
}

export const HrisProvider: React.FC<HrisProviderProps> = ({ children }) => {
  // lazy load logic. if 0 - do not refetch.
  const [linkToken, setLinkToken] = useState<LinkToken>();
  const [refetchToken, setRefetchToken] = useState<number>(0);
  const [integrations, setIntegrations] = useState<HrisIntegrationUI[]>([]);
  const [loading, setLoading] = useState(false);

  const handleError = (msg: string, error: Error) => {
    setLoading(false);
    showError(msg);
    throw error;
  };

  const triggerRefetch = () => setRefetchToken((prev) => prev + 1);

  // throwable
  const refetchIntegrations = async () => {
    setLoading(true);
    try {
      const res = await get('/hris_integration');
      setIntegrations((res ?? []).map(hrisIntegrationResToUI));
      setLoading(false);
    } catch (err) {
      handleError('Error fetching HRIS integrations', err as Error);
    }
  };

  const cleanLinkToken = async (): Promise<void> => {
    setLinkToken(undefined);
    await refetchIntegrations();
  };

  // throwable
  const createLinkToken = async (id: number | null): Promise<void> => {
    setLoading(true);
    try {
      let res;
      if (!id) {
        res = await post('/hris_integration/link_token');
      } else {
        res = await put(`/hris_integration/${id}/link_token`);
      }
      setLinkToken({
        id: res?.id,
        token: res?.link_token,
      });
      setLoading(false);
    } catch (err) {
      handleError('Error creating HRIS link token', err as Error);
    }
  };

  // throwable
  const createIntegration = async (id: number, token: string): Promise<void> => {
    setLoading(true);
    try {
      await post('/hris_integration', { id, token });
      message.success('Connected for HRIS integration');
    } catch (err) {
      handleError('Error creating HRIS integration', err as Error);
    }
  };

  // throwable
  const removeIntegration = async (id: number, doRefetch = true): Promise<void> => {
    setLoading(true);
    try {
      await remove(`/hris_integration/${id}`);
      message.success('Changes were saved');
    } catch (err) {
      handleError(`Can not remove HRIS integration with id: ${id}`, err as Error);
    } finally {
      if (doRefetch) {
        await refetchIntegrations();
      } else {
        setLoading(false);
      }
    }
  };

  // throwable
  const disableIntegration = async (id: number): Promise<void> => {
    setLoading(true);
    try {
      await patch(`/hris_integration/${id}/disable`);
      message.success('Changes were saved');
    } catch (err) {
      handleError(`Can not disable HRIS integration with id: ${id}`, err as Error);
    } finally {
      await refetchIntegrations();
    }
  };

  // throwable
  const enableIntegration = async (id: number): Promise<void> => {
    setLoading(true);
    try {
      await patch(`/hris_integration/${id}/enable`);
      message.success('Changes were saved');
    } catch (err) {
      handleError(`Can not enable HRIS integration with id${id}`, err as Error);
    } finally {
      await refetchIntegrations();
    }
  };

  // throwable
  const dataSync = async (): Promise<void> => {
    setLoading(true);
    try {
      await post('/hris_integration/sync');
      message.success('Sync was started');
    } catch (err) {
      handleError('Error syncing with Merge', err as Error);
    } finally {
      await refetchIntegrations();
    }
  };

  useEffect(() => {
    if (refetchToken !== 0) {
      refetchIntegrations();
    }
  }, [refetchToken]);

  const value = useMemo(
    () => ({
      cleanLinkToken,
      createIntegration,
      createLinkToken,
      dataSync,
      disableIntegration,
      enableIntegration,
      hasEnabledIntegration: integrations.some((i) => i.enabled),
      integrations,
      linkToken,
      loading,
      refetchToken,
      removeIntegration,
      setRefetchToken,
      triggerRefetch,
    }),
    [
      cleanLinkToken,
      createLinkToken,
      disableIntegration,
      enableIntegration,
      createIntegration,
      dataSync,
      integrations,
      linkToken,
      loading,
      triggerRefetch,
      refetchToken,
      removeIntegration,
      setRefetchToken,
    ],
  );
  return <HrisContext.Provider value={value}>{children}</HrisContext.Provider>;
};

export const useHris = (): HrisContextType => {
  const context = useContext(HrisContext);

  if (!context) {
    throw new Error('useHris must be used within a HrisContextType');
  }

  useEffect(() => {
    if (context.refetchToken === 0) {
      context.triggerRefetch();
    }
  }, [context.refetchToken]);

  return context;
};
