import { sanitize } from 'dompurify';

import { TOP_STATEMENTS_KEYTERMS } from '../../../constants';
import { toArray, toString } from '../../../utils/helpers';

export function cleanStatementData(categoryQuestions, filters, settings, questions) {
  const name = toString(filters?.user360?.fullName);
  const isVisible = (id, keyTerm) => {
    const index = toArray(settings?.questionsVisibility).findIndex((i) => i.id === id);
    if (index === -1) {
      if (
        TOP_STATEMENTS_KEYTERMS.indexOf(keyTerm.toLowerCase().trim()) !== -1 ||
        filters?.surveyType !== 'regular'
      ) {
        return true;
      }
      return false;
    }
    return settings?.questionsVisibility[index]?.showInAppendix;
  };
  return toArray(categoryQuestions).map((c) => ({
    categoryId: c.categoryId,
    categoryTextval: c.categoryTextval,
    employeeDimension: toArray(c.employeeDimension)
      .filter((q) => (filters.surveyType !== '360' ? isVisible(q.questionId, q.keyterm) : true))
      .filter((q) =>
        filters.surveyType === '360' ? !questions[q.questionId]?.relatedQuestionId : true,
      )
      .map((q) => ({
        enableTopics: q.enableTopics,
        questionId: q.questionId,
        questionText: sanitize(q.questionText, { ALLOWED_TAGS: [] })
          .replaceAll('[Name]', name)
          .replaceAll('[name]', name),
        dimensionValues: toArray(q.dimensionValues).map((d) => ({
          name: d.name,
          topics: toArray(d.topics).map((t) => ({
            answerval: t.answerval,
            keywords: t.keywords,
            percentage: t.percentage,
            id: t.id,
            summary: t.summary,
            statements: t.statements?.map((s) => ({
              answerval: s.answerval,
              keywords: s.keywords,
              percentage: s.percentage,
              id: s.id,
            })),
          })),
        })),
      })),
  }));
}

export function cleanStatementDataByDimmension(categoryQuestions, filters, settings) {
  const name = toString(filters?.user360?.fullName);
  const isVisible = (id, keyTerm) => {
    const index = toArray(settings.questionsVisibility).findIndex((i) => i.id === id);
    if (index === -1) {
      if (
        TOP_STATEMENTS_KEYTERMS.indexOf(keyTerm.toLowerCase().trim()) !== -1 ||
        filters?.surveyType !== 'regular'
      ) {
        return true;
      }
      return false;
    }
    return settings.questionsVisibility[index]?.showInAppendix;
  };
  return toArray(categoryQuestions).map((c) => ({
    categoryId: c.categoryId,
    categoryTextval: c.categoryTextval,
    employeeDimension: toArray(c.employeeDimension)
      .filter((q) => (filters.surveyType !== '360' ? isVisible(q.questionId, q.keyterm) : true))
      .map((q) => ({
        enableTopics: q.enableTopics,
        questionId: q.questionId,
        questionText: sanitize(q.questionText, { ALLOWED_TAGS: [] })
          .replaceAll('[Name]', name)
          .replaceAll('[name]', name),
        dimensionValues: toArray(q.dimensionValues).map((d) => ({
          name: d.name,
          topics: toArray(d.topics).map((t) => ({
            answerval: t.answerval,
            keywords: t.keywords,
            percentage: t.percentage,
            id: t.id,
            summary: t.summary,
            statements: t.statements?.map((s) => ({
              answerval: s.answerval,
              keywords: s.keywords,
              percentage: s.percentage,
              id: s.id,
            })),
          })),
        })),
      })),
  }));
}
