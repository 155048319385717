import { ReactElement } from 'react';
import { Modal } from 'antd';
import { sanitize } from 'dompurify';

function showAlert(
  type: 'error' | 'info' | 'warning' | 'success',
  content: string | ReactElement,
  title: string | undefined,
  onOk?: () => void,
) {
  Modal[type]({
    content:
      typeof content === 'string' ? (
        <div dangerouslySetInnerHTML={{ __html: sanitize(content) }} />
      ) : (
        content
      ),
    onOk,
    title,
  });
}

export function showError(content: string | ReactElement, title = 'Error') {
  showAlert('error', content, title);
}

export function showInfo(content: string | ReactElement, title = '') {
  showAlert('info', content, title);
}

export function showWarning(
  content: string | ReactElement,
  title = '',
  onOk: () => void = () => {
    /**/
  },
) {
  showAlert('warning', content, title, onOk);
}

export function showSuccess(content: string | ReactElement, title?: string) {
  showAlert('success', content, title);
}
