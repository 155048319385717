import { ReactElement, ReactNode, useState } from 'react';
import { Cascader, Empty } from 'antd';
import { cloneDeep } from 'lodash';

const { SHOW_CHILD } = Cascader;
interface OptionUI {
  value: string;
  label: string;
  userId: number;
  children?: OptionUI[];
  disableCheckbox?: boolean;
}

interface DimensionSelectorProps {
  onChange: (
    values: (string | number)[][],
    fullDataValues: OptionUI[][],
    userId?: number,
    surveyId?: number,
  ) => void;
  values: (string | number)[][];
  options: OptionUI[];
  userId?: number;
  surveyId?: number;
  runValidation?: boolean;
}

const truncateLabel = (label: string): ReactElement =>
  label.length > 26 ? (
    <span title={label}>{`${label.slice(0, 23)}...`}</span>
  ) : (
    <span>{label}</span>
  );

const truncateToStr = (label: string): string =>
  label.length > 26 ? `${label.slice(0, 23)}...` : label;

const displayRender = (labels: string[]) => (
  <span>
    {labels.reduce<ReactNode[]>(
      (prev: ReactNode[], curr: string, index: number) =>
        index > 0 ? [...prev, ' - ', truncateLabel(curr)] : [...prev, truncateLabel(curr)],
      [],
    )}
  </span>
);

const optionRender = (o: OptionUI) => {
  if (o.value === '__EMPTY__') {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  return truncateToStr(o.label ?? '');
};

const DimensionSelector = ({
  onChange = () => {},
  values = [],
  options = [],
  userId,
  surveyId,
  runValidation = false,
}: DimensionSelectorProps) => {
  const [selectedValues, setSelectedValues] = useState<(string | number)[][]>([]);

  if (options.length === 0) return null;

  return (
    <div className="dimension-selector">
      <Cascader
        style={{ width: 400 }}
        options={options}
        value={values}
        onChange={(changedValues, fullDataValues) => {
          // Unselect "All Survey Results" when user select a proper dimension
          const cloneChangedValues = cloneDeep(changedValues);
          const cloneFullDataValues = cloneDeep(fullDataValues);
          if (changedValues.length > 1 && changedValues[0][0] === 'fullReportAccess') {
            cloneChangedValues.shift();
            cloneFullDataValues.shift();
          }
          setSelectedValues(cloneChangedValues);
          onChange(cloneDeep(cloneChangedValues), cloneFullDataValues, userId, surveyId);
        }}
        multiple
        allowClear={false}
        status={runValidation && !values.length && !selectedValues.length ? 'error' : ''}
        displayRender={displayRender}
        showCheckedStrategy={SHOW_CHILD}
        showSearch={{
          render: (inputValue, path) =>
            path.map((x, i) => (
              <>
                {i > 0 ? ' - ' : ''}
                {truncateLabel(x.label)}
              </>
            )),
        }}
        optionRender={optionRender}
      />
    </div>
  );
};

export default DimensionSelector;
