import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import moment from 'moment';

import versionData from '../../version.json';

import './styles.scss';

const { version } = versionData;

const Footer = () => (
  <footer className="footer">
    <div className="footer__content">
      <Row justify="space-between">
        <Col>
          <Row gutter={[16, 0]}>
            <Col>
              Powered by&nbsp;
              <a href="http://www.entromy.com/" target="_blank" rel="noreferrer">
                <b>Entromy</b>
              </a>
            </Col>
            <Col>
              <Link to="/privacy-policy">Privacy Policy</Link>
              &nbsp;/&nbsp;
              <Link to="/terms-of-service">Terms of Service</Link>
            </Col>
          </Row>
        </Col>
        <Col>
          <b>Entromy</b> &copy;
          {moment().format('YYYY')} {version}
        </Col>
      </Row>
    </div>
  </footer>
);

export default Footer;
