import { QUESTIONS_TYPES } from '../../../constants';
import { toString } from '../../../utils/helpers';

export function mapCategories(list, categories, surveyType) {
  list.forEach((item) => {
    if (surveyType === '360') {
      item.score = Math.round(item.score / 2) / 10;
    }
  });
  return list;
}

export function mapQuestions(list, questions, surveyType, filters) {
  list.forEach((item) => {
    const question = questions[item.id] || {};
    item.categoryId = question.categoryId;
    item.text = question.text;
    item.keyTerm = question.keyTerm;
    item.typeId = question.typeId;
    item.themeId = question.themeId;
    item.metadata = question.questionMetadata;
    if (surveyType === '360') {
      if (question.typeId === QUESTIONS_TYPES.FIXED) {
        item.score = Math.round(item.score / 2) / 10;
      }
      const name = toString(filters?.user360?.fullName);
      if (item.text) {
        item.text = item.text.replaceAll('[Name]', name);
        item.text = item.text.replaceAll('[name]', name);
      }
    }
  });
  return list;
}

export function mapDeltas(current, prior, filters) {
  current.forEach((item) => {
    const prev = prior.find((i) => i.keyTerm === item.keyTerm && i.categoryId === item.categoryId);
    if (prev) {
      item.delta = item.score - prev.score;
      if (filters?.surveyType === '360') {
        item.delta = Math.round(item.delta * 10) / 10;
      }
    }
  });
}

const asc = (arr) => arr.sort((a, b) => a - b);

const sum = (arr) => arr.reduce((a, b) => a + b, 0);

const mean = (arr) => sum(arr) / arr.length;

// sample standard deviation
export function std(arr) {
  const mu = mean(arr);
  const diffArr = arr.map((a) => (a - mu) ** 2);
  return Math.sqrt(sum(diffArr) / (arr.length - 1));
}

export function quantile(arr, q) {
  const sorted = asc(arr);
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  }
  return sorted[base];
}

export function getBaseUrl(filters) {
  return filters.reportType === 'vcp' ? '/reporting/vcp' : '/reporting/discussion_materials';
}
